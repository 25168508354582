import {BASE_URL,DEFAULT_KEY} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

//Login Service
export default class LoginService{
    key =localStorage.getItem('apiKey')
    async LoginUser(userData){
        const key = localStorage.getItem('apiKey');
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'auth/access', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : key,
                },
                body: JSON.stringify(userData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    async Forgotpassword(cridential){
        return new Promise(async(resolve,reject)=>{
            await fetch(BASE_URL+'auth/password/forget',{
                method: 'POST',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       :  this.key   // API key
                },
                body: JSON.stringify(cridential),
            })
            .then((response) => response)
            .then((res)=>{
                resolve(res)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    }

    //change passowrd
    async ChangePasword(cridential){
        return new Promise(async(resolve,reject)=>{
            await fetch(BASE_URL+'profile/password',{
                method: 'PUT',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       :  this.key,
                    'access-token'  : UserStore.key   
                },
                body: JSON.stringify(cridential),
            })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        })
    }

    //change transaction password
    async ChangeTransactionPassword(cridential){
        return new Promise(async(resolve,reject)=>{
            await fetch(BASE_URL+'profile/transaction_password',{
                method: 'PUT',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       :  this.key,
                    'access-token'  : UserStore.key   
                },
                body: JSON.stringify(cridential),
            })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
        })
    }


    //forgot transaction password
    async forgotTransactionPassword(formData){
        const response = await fetch(BASE_URL+'profile/forget_transaction_password', {     //url
            method: 'POST',                 //method
            headers : {
                'api-key'       : this.key,
                'Content-Type'  : 'application/json',
                'access-token'  : UserStore.key
            },
            body : JSON.stringify(formData)
        })
        if(response.status === 204){
            return {
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }
    //get the API KEY
    async getApi(admin){
        const response = await fetch(`${BASE_URL}common/api_key?admin_user_name=${admin}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : DEFAULT_KEY
               
            }
        })
        const result = await response.json();
        return result;
    }
}