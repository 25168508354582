import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';


//import Nophoto from '../../assets/images/nophoto/no_photo.jpg'

export default class DashboardService{
    key =localStorage.getItem('apiKey')
    //dashboard tiles 
    async dashboardTiles(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/dashboard_tile', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //profile data
    async dashboardUser(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/user_dashboard', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //new joining
    async dashboardJoining(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/latest_members', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //chart 
    async DashboardChart(range="monthly_joining_graph"){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/latest_member_joining?range='+range, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    //dashboard main view api service
    async DashboardLoad(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/dashboard', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                 
                    
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    //joining graph fiter api call
    async JoiningGraphFilter(range){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/graphFilter?range='+range, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    //tiles item filter changes
    async TileFilter(type,range){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/tileFilter?range='+range+'&type='+type, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

}