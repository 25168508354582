import { useEffect , useState }from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import PageTitle from '../../components/common/pageTitle';
//import Spinner from '../../shared/Spinner';
import { withRouter } from 'react-router';
import { TreeContent } from '../../components/network/board';
import BoardService from '../../../service/tree/board';
import companyName from '../../../store/mobxStore/companyName';




const BoardViewTree = (props) => {

   const [state , setState]= useState({
        loading: true,
        data: [],
        depth: 0,
        width: 0,
        toolTipConfig: {},
        table_status: false
    })

    const service = new BoardService();

useEffect(()=>{
    const { id, userId } = props.match.params;
    if (id && userId) {
        service.userBoard(id, userId).then(res => {
            setState({
                loading: false
            });
            if (res.status) {
                setState({
                    data: res.data.TreeData,
                    depth: res.data.board_depth,
                    width: res.data.board_width,
                    toolTipConfig: res.data.tooltip_config,
                    table_status: res.data.table_status === 'yes' ? true : false
                })
            } else {
                if (res.error.code === 1002) {
                    props.history.push('/logout')
                } else if (res.error.code === 1043) {
                    props.history.push('/BoardView');
                }
            }
        })
    } else {
        props.history.goBack()
    }
        // this.service.userBoard()

},[])
   
const { t } = props;
   
   
    return (
        
            <div className="h-100">
                <Helmet>
                    <title>{companyName.companyName} | {t('Sidemenu.boardView')}</title>
                </Helmet>
                <PageTitle title="boardView" buttonOn={false} />
                <TreeContent
                    {...state}
                />
            </div>
    )
}
export default withTranslation()((withRouter(BoardViewTree)))