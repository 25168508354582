import React from 'react';
//css
import style from './crm.module.scss';
//service
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

function FollowUpMissedOrToday(props) {
    const {t}  = useTranslation();
    const history = useHistory();
    const ViewLeadClick  = id =>{
        history.push(`/crm/timeline/${id}`)
    }
    const follow_data = props.followupdata &&props.followupdata.map((item, key) => {
        return (
            <tr key={key}>             
                <td>1</td>
                <td>{item.first_name}</td>
                <td>{item.added_by_name}</td>
                <td>{item.skype_id}</td>
                <td><i className={`${style.text_info} fa fa-edit`} onClick={()=>props.showModal('edit',item.id,props.type)} ></i></td>
                <td><i className={`${style.text_primary} fa fa-plus-circle`} onClick={()=>props.showModal('addFollowup',item.id,props.type)}></i></td>
                <td><i className={`${style.text_primary} fa fa-book`} onClick={()=>ViewLeadClick(item.id)} ></i></td>
            </tr>
        )
    });

    return (
            <div className={`${style.panel} ${style.papanel_defaultnel}`}>
                <div className={`${style.panel_body}`} style={{marginTop:'20px'}}>
                <legend><span className={`${style.fieldset_legend}`}>{props.title}</span></legend>
                    <div className={`${style.table_responsive} ${style.panel} ${style.papanel_defaultnel}`}>
                        {props.followupdata && props.followupdata.length >0 ? 
                        
                        <table className={`table`} >
                        <thead>
                            <tr> 
                                <th>#</th>
                                <th>{t('Crm.name')}</th>
                                <th>{t('Crm.assignedTo')}</th>
                                <th>{t('Crm.skypeID')}</th>
                                <th>{t('Crm.editLead')}</th>
                                <th>{t('Crm.addFollowup')}</th>
                                <th>{t('Crm.viewDetails')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {follow_data}
                        </tbody>
                        </table>
                        :
                        <table className={`table`} >
                            <thead>
                                <tr className="th" align="center">
                                    <td><h3 align="center">{t('Common.noToday')}</h3></td>
                                </tr>
                            </thead>
                        </table>
                    }
                    </div>
                </div>
            </div>
    );
}
export default FollowUpMissedOrToday