import React, { useEffect } from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {logout} from '../../../../store/action';
import UserStore from '../../../../store/UserStore';
function Logout() {
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(logout());
        localStorage.removeItem('login');
        localStorage.removeItem('apiKey');
        UserStore.key = ''
        history.push('/login');
    })
    return (
        <div></div>
    )
}

export default Logout
