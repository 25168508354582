import React, {useRef} from 'react'
import {Modal,Button,Table} from 'react-bootstrap';
import style from './history.module.scss';
import {useTranslation}from 'react-i18next';
//import {CustumBtnGroup} from '../common';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import OrderHistoryPrintView from'./OrderHistoryPrintView'

function Invoice(props) {
    const {t} = useTranslation();
    const componentRef = useRef();
    // const printInvoice = () =>{
     
    // }
    return (
        <Modal show={props.show} onHide={props.handleClose} contentClassName={style.modalContent}>
                <Modal.Header closeButton className={`${style.modalHeader}`}>
                    <Modal.Title>{t('Common.invoice')}</Modal.Title>
                </Modal.Header>
                <Modal.Body  style={{background : "#fff"}}>
                    <div className={`${style.invoiceBody}`}>
                        <div className={`${style.printButton}`}>
                        <ReactToPrint
          trigger={() => <Button><i className="fa fa-print"></i>{t('Button.print')}</Button>}
          content={() => componentRef.current}
        />
      
      <div style={{ display: "none" }}>
      <OrderHistoryPrintView ref = {componentRef} {...props}/>
      </div>
                        </div>
                        <div className={`${style.printArea}`}>
                            <Table responsive borderless width="700" height="100">
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            <h3>
                                                <b>{t('Common.orderId')}:
                                                    <font color="#7266ba">#{props.data.order_id}</font>
                                                </b>
                                            </h3>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <hr/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <b>{t('Common.dateAdded')} &nbsp;</b>
                                            {moment(props.data.date_added).format('YYYY/MM/DD')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <b>{t('Common.shippingMethod')} &nbsp;</b>
                                            {props.data.shipping_method}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <b>{t('profile.paymentMethod')} &nbsp;</b>
                                            {props.data.payment_method}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <hr/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h2>{t('Common.paymentAddress')}</h2>
                                            <b>{props.data.full_name}</b><br/>
                                            {props.data.payment_address_1} <br/>
                                            {props.data.payment_city}, {props.data.payment_zone} <br/>
                                            {props.data.payment_country}
                                        </td>
                                        <td>
                                            <h2>{t('Common.paymentAddress')}</h2>
                                            <b>{props.data.full_name}</b><br/>
                                            {props.data.payment_address_1} <br/>
                                            {props.data.shipping_city}, {props.data.shipping_zone} <br/>
                                            {props.data.shipping_country}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <hr/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <h2>
                                                <b>{t('Common.orderProducts')} </b>
                                            </h2>
                                                <hr/>
                                                <Table bordered striped hover className={style.bordered}>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                {t('profile.product')}
                                                            </th>
                                                            <th>
                                                                {t('Common.quantity')}
                                                            </th>
                                                            <th>
                                                                {t('Common.pairValue')}
                                                            </th>
                                                            <th>
                                                                {t('Common.price')}
                                                            </th>
                                                            <th>
                                                                {t('Common.totalPairValue')}
                                                            </th>
                                                            <th>
                                                                {t('Common.total')}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            props.data.products&&
                                                            props.data.products.map((product,index)=>
                                                                <tr key={index}>
                                                                    <td>{product.name}</td>
                                                                    <td>{product.quantity}</td>
                                                                    <td>{product.pair_value}</td>
                                                                    <td>
                                                                        {props.currency.currentCurr} 
                                                                        {(parseInt(product.price)*props.currency.value).toFixed(props.currency.precision)}
                                                                    </td>
                                                                    <td>
                                                                        {parseInt(product.quantity)* parseInt(product.pair_value)}
                                                                    </td>
                                                                    <td>
                                                                        {props.currency.currentCurr} 
                                                                        {(parseInt(product.total)*props.currency.value).toFixed(props.currency.precision)}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        <tr>
                                                            <td colSpan="6">
                                                                <hr/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <br/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <table border="0" width="30%" height="100"  align="right" className={style.tableBottom}>
                                                <tbody>
                                                    {
                                                        props.data.order_total &&
                                                        props.data.order_total.map((order,index)=>
                                                            <tr key={index}>
                                                                <td width="60%">
                                                                    <h6>{order.title}</h6>
                                                                </td>
                                                                <td>
                                                                    {props.currency.currentCurr}
                                                                    {(parseInt(order.value)*props.currency.value).toFixed(props.currency.precision)}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="default" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>
    )
}

export default Invoice
