import React, { useState } from 'react';
import {Modal,Button} from 'react-bootstrap';
import style from './cart.module.scss';
import {useTranslation} from 'react-i18next';
import {FormInputControl,AlertBs} from '../common';
import { Form } from 'reactstrap';
import { ConstructionOutlined } from '@mui/icons-material';
const FieldSet = [
    {
        code : 'name',
        value : '',
        type : 'text',
        required : true,
        fieldname : 'name'
    },
    {
        code : 'address',
        value : '',
        type : 'text',
        required : true,
        fieldname : 'address'
    },
    {
        code : 'zipCode',
        value : '',
        type : 'text',
        required : true,
        fieldname : 'zip_code'
    },
    {
        code : 'city',
        value : '',
        type : 'text',
        required : true,
        fieldname : 'city'
    },
    {
        code : 'phoneNumber',
        value : '',
        type : 'text',
        required : true,
        fieldname : 'phone'
    }
]
function AddModal(props) {
    const {t} = useTranslation();
    const [state,setState] =useState({
        field : FieldSet,
        alert : {
            show : false,
            variant : '',
            message : ''
        }
    })

    //change handler
    const changeHandler = (event) =>{
        const {name,value} = event.target;
        const TempData = state.field.map(item=>{
            if(item.code === name){
                return{
                    ...item,
                    value : value,
                    error : null
                }
            }
            return {
                ...item
            }
        });
        setState(prev=>({
            ...prev,
            field  : TempData
        }))
    }

    // onSubmit form
    const submit = (e) =>{
        e.preventDefault();
        let isvalid = true;
        const validate = state.field.map((item,index)=>{
            if(!item.value){
                isvalid = false;
                return {
                    ...item,
                    error : {
                        error : 'validation.required',
                        field : {
                            field : item.code
                        }
                    }
                }
            }else if(item.code === 'phoneNumber'){//validate the phone number
                var regexPattern=new RegExp(/^[0-9-+]+$/);    // regular expression pattern
                if(!regexPattern.test(item.value)){
                    isvalid = false;
                    return {
                        ...item,
                        error : {
                            error : 'validation.fieldAreNotCurrectFormat',
                            field : {
                                field : item.code
                            }
                        }
                    }
                } 
            }
            else if(item.code === 'address'){//chck address length
                if(item.value.length<3){
                    isvalid = false;
                    return {
                        ...item,
                        error : {
                            error : 'validation.fieldAreNotCurrectFormat',
                            field : {
                                field : item.code
                            }
                        }
                    }
                } 
            }
            return{
                ...item
            }
        });
        if(isvalid){
            const formdata = {};
            state.field.map((item)=>{
                formdata[item.fieldname] = item.value;
                return true
            })
            props.submit(formdata)
            setState(prev=>({
                ...prev,
                field  : FieldSet
            }))
       
            
           

        }else{

            setState(prev=>({
                ...prev,
                field : validate,
                alert : {
                    show : true,
                    message : 'validation.checkValueYouHaveSubmitted',
                    variant : 'danger'
                }
            }))
        }
    }
    //close alert message 
    const closeAlert = () =>{
        setState(prev=>({
            ...prev,
            alert : {
                show : false,
                message : '',
                variant : ''
            }
        }))
    }
    const closeHandle = () =>
    {
        
      
        setState(prevState=>({
            ...prevState,
                close : true
        }))
    }
    return (
        <Modal 
            show={props.show} 
           onHide={props.handleClose} 
            contentClassName={style.modalContent}
            className={`${style.addAddressModal} animate__animated animate__fadeInDown  animate__faster	5s`}>
            <Form onSubmit={submit}>
        <Modal.Header closeButton className={style.modalHeader}>
          <Modal.Title>{t('Common.enterNewShippingAddress')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={style.addressCardBody}>
            <AlertBs 
                show={state.alert.show}
                varient={state.alert.variant}
                message={state.alert.message}
                close={closeAlert}
            />
            {
                state.field.map((item,index)=>{
                    return <div key={index} className="mb-3">
                                <FormInputControl
                                        label={t(`Common.${item.code}`)}
                                        required={item.required}
                                      error={item.error}
                                        inputProps={{
                                            name:item.code,
                                            type:item.type,
                                            value:item.value,
                                            onChange:(e=>{
                                                changeHandler(e,index)
                                            })
                                        }}
                                />
                            </div>
                })
            }
        </Modal.Body>
        <Modal.Footer className={style.modalFooter}>
          <Button className={`${style.btnAddon} ${style.btnPrimary}`} type="submit">
            {t('Button.saveAddress')}
          </Button>
        </Modal.Footer>
            </Form>
      </Modal>
    )
}

export default AddModal
