import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
//service
import {CurrencyDecimalFormat} from '../../helper';
import { BASE } from '../../../configuration/apiconfig';
//styles 
import style from  './payout.module.scss';
function Tiles({tilesData}) {
    const {t} = useTranslation();
    const [data,setData] = useState([]);
    const Currency       = useSelector(state => state.curr);
    useEffect(()=>{
            if(tilesData){
                let PayoutTiles = tilesData.map((items)=>{
                    var background ;
                    var img;
                    switch(items.text){
                        case 'approved' :
                            background = style.blue;
                            img = 'Approved.png';
                            break;
                        case 'paid':
                            background = style.green;
                            img = 'paid.png';
                            break;
                        case 'rejected':
                            img = "Rejected.png";
                            background = style.red;
                            break;
                        default:
                            background = style.yellow;
                            img = 'pending.png'; 
                    }
                    return{
                        amount : items.amount,
                        text   : items.text,
                        background  : background,
                        img : img
                    }
                });
                setData(PayoutTiles);
            }
    },[tilesData])

    return (
       <div className={`${style.payoutTiles}`}>
           {
                data.map((tiles,index)=>{
                    return <div className={`${style.Tiles} d-flex`} key={index}>
                                <div className={`${style.leftIcon} ${tiles.background}`}>
                                    <img src={`${BASE}backoffice/public_html//images/newui/${tiles.img}`} alt=""/>
                                </div>
                                <div className={style.tilesData}>
                                    <h4>{t('Common.'+tiles.text)}</h4>
                                    <span className={style.TilesAmount} title={(tiles.amount*Currency.value).toFixed(Currency.precision)}>{Currency.currentCurr} {CurrencyDecimalFormat(tiles.amount*Currency.value,Currency.precision)}</span>
                                </div>
                            </div> 
                })
            }
       </div> 
    )
}

export default Tiles
