import React,{useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//service 
import {CurrencyDecimalFormat} from '../../helper';
import style from './ewallet.module.scss';
function WalletTable({ContentData,paginationchange}) {
    const {t}  = useTranslation();
    const Currency       = useSelector(state => state.curr);
    const columns = [
        {
          name: t('Common.description').toUpperCase(),
          selector: row=>row.description,
          width : "30%" 
        },
        {
          name: t('Common.amount').toUpperCase(),
          selector: row=>row.amount,                                                                         
          
          cell: (row) => <span className={`${row.classnameamount} ${style.badge}`}>{Currency.currentCurr} {(row.amount*Currency.value).toFixed(Currency.precision)}</span>
        },
        {
            name: t('Common.transactionDate').toUpperCase(),
            selector:row=>row.date,
            sortable: true
        },
        {
          name: t('Common.balance').toUpperCase(),
          selector: row=>row.balance,                                                    
          cell: (row) => <span className={`${style.balanceAmount} ${style.badge}`}>{Currency.currentCurr} {((row.balance) * Currency.value).toFixed(Currency.precision)}</span>
        }
      ];
    const [state, setState] = useState({data: [] });
    const { data} = state;
    
    useEffect(()=>{
        if(ContentData.data){

            let NewData = ContentData.data.map((resData)=>{
                if(resData.type==="credit"){
                    return {
                        classnameamount : style.credit,
                        amount : resData.amount,
                        transaction_fee : resData.transaction_fee,
                        description : resData.description,
                        date : resData.date,
                        balance : resData.balance 
                    }
                }else if(resData.type==="debit"){
                    return {
                        classnameamount : style.debit,
                        amount : resData.amount,
                        transaction_fee : resData.transaction_fee,
                        description : resData.description,
                        date : resData.date
                    }
                }
                return null
            })
            setState(prev => ({
                ...prev,
                data:  NewData
            }));
        }
    },[ContentData]);
      const handlePerRowsChange = (perPage, inPage) => {
        paginationchange(inPage,perPage)
      };
      const handlePageChange = inPage => {
        paginationchange(inPage,ContentData.perPage)
      };
    return (
      <div>

        <DataTable
            columns={columns}
            data={data}
            highlightOnHover
            progressPending={ContentData.pending}
            progressComponent={<div>Loading</div>}
            persistTableHead
            noHeader
            pagination
            paginationServer
            responsive
            paginationTotalRows={ContentData.totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            noDataComponent={t('Common.noDataRecordsToDisplay')}
        />
      </div>
    )
}

export default WalletTable
