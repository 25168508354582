import React,{useState} from 'react';
import { Dropdown,Button} from 'react-bootstrap';
import style from './payout.module.scss';
import { useTranslation } from 'react-i18next';
const PayoutToggle = React.forwardRef(({ children, onClick }, ref) => (
    
    <span
      className={style.selectedCatgory}
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* Render custom icon here */}
      {children}
      <span className={style.caretIcon}></span>
    </span>
  ));
function DropDown({handler,loader}) {
    const {t} =  useTranslation();
    const [state,setState] = useState({
        selectedEvent  : 'paid'
    });
    const {selectedEvent} = state;
    
    //dropdown Data
    const DropDownData = ['pending','approved','paid','rejected']
    
    //dropdownChnage handler
    const ChangeHandler = (eventKey) =>{
        setState({selectedEvent : eventKey});
    }

    //reset value
    const resetValue= () =>{
        loader.reset = true
        setState({selectedEvent : 'paid'});
        handler('paid')
    }

    //serch button click
    const submitHandler = () =>{

        loader.search = true

        handler(state.selectedEvent)
    }

    return (
        <div className={style.filterSection}>
            <Dropdown className={`${style.filterDropDown}`} onSelect={ChangeHandler}>
                <Dropdown.Toggle as={PayoutToggle}>
                    {t('Common.status')+' - '+ t('Common.'+selectedEvent)}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {
                    DropDownData.map((items,index)=>{
                        return <Dropdown.Item key={index} eventKey={items}>{t('Common.status')+' - '+ t('Common.'+items)}</Dropdown.Item>
                    })
                }
                </Dropdown.Menu>
            </Dropdown>
            <div className={`${style.filterButtons}`}>
                    <Button variant="info" className={style.padding5} 
                        onClick={submitHandler}
                    >
                                {loader.search?
                            <i className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            /> : ''
                   
                                }

                        {t('Button.search')}
                    </Button>
                    <Button className={style.padding5} 
                        onClick={resetValue}
                    >

{       loader.reset?
                            <i className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            /> : ''
}

                        {t('Button.reset')}
                    </Button>
                    
            </div>
        </div>
    )
}

export default DropDown;
