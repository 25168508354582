import React, { useState, useEffect,useRef ,useLayoutEffect} from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { Header, Banner, Plans, About, Contact, Footer,Scrollbutton } from '../../components/replica';
import { useParams, useHistory } from 'react-router-dom';
import ReplicaSiteService from '../../../service/replica/replica';
import AppInfoservice from '../../../service/common/Appinfo';







function Home() {
    const path = useParams();
    const history = useHistory();
    const service = new ReplicaSiteService();
    const app = new AppInfoservice();
  
    
    const [state, setState] = useState({
        header_data: [],
        policy_data: [],
        banner_data: [],
        loding:true,
        logo:'',


    })



    useEffect(() => {

        service.getHeader(path.user_name).then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    header_data: res.data,
                    loding:false,

                }));
            } else {
                alert("Invalid Url")
                
            }

        })
    app.Appinfo().then((res)=>{
        console.log(res)
        if(res.status){
            setState(prevState => ({
                ...prevState,
                logo: res.data.company_info.login_logo,

            }));

        }


    })
        getBanner();
        getPolicy();
        


    }, [])

   

    const getBanner = () => {
        service.getBanner(path.user_name).then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    banner_data: res.data

                }));
            } else {
               alert("Invalid Url")
            }

        })

    }
    const getPolicy = () => {
        service.getPolicy(path.user_name).then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    policy_data: res.data

                }));
            } else {
        
            }

        })

    }


    return (
        <div className="h-100">
                {!state.loding &&
            <>

     


                {state.header_data &&
                    <Header
                        data={state.header_data}
                        user_name={path.user_name}
                        logo={state.logo}
                        showmenu={true}
                    />

                }

                {state.banner_data && state.policy_data &&
                    <Banner
                        data={state}
                        header_data={state.header_data}
                    />
                }
                {state.policy_data &&
                    <Plans
                        data={state}
                    />
                }
                {state.policy_data &&
             
                 <About
                 data={state}
                    />
            
                  
                }
                {state.policy_data &&
                    <Contact 
                        data={state}
                        user_name={path.user_name}
                    />
                }
                    <Scrollbutton/>
                {state.policy_data &&
                    <Footer
                        data={state}
                        user_name={path.user_name}
                    />
                }

        

            </>
            
}




        </div>
    );

}

export default withTranslation()(withRouter(Home))