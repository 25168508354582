import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import style from './epin.module.scss';
import {DataTable} from './';
import {CurrencyDecimalFormat} from '../../helper';
function EpinTransferhistory(props) {
    //redux value get the current currency
    const Currency       = useSelector(state => state.curr);
    const {t} = useTranslation(); // translation
    //active columns 
    const Columns = [
        {
          name: t('Common.memberName').toUpperCase(),
          selector: row=>row.member_name,
        //   width : "30%"
        },
        {
          name: t('Common.epin').toUpperCase(),
          selector: row=>row.epin,
        //   cell: (row) => <span className={`${style.badgeblue} ${style.badge}`}>{Currency.currentCurr} {CurrencyDecimalFormat(row.amount*Currency.value,Currency.precision)}</span>
        },
        {
            name: t('Common.amount').toUpperCase(),
            selector: row=>row.amount,
            sortable: true,
            cell: (row) => <span className={`${style.badgegreen} ${style.badge}`}>{Currency.currentCurr} {CurrencyDecimalFormat(row.amount*Currency.value,Currency.precision)}</span>
        },
        {
            name: t('Common.transferDate').toUpperCase(),
            selector: row=>row.transferred_date,
            sortable: true,
        },
        {
            name: t('Common.transferType').toUpperCase(),
            selector: row=>row.type,
            sortable: true,
        }
        
      ];
        //handle row per change
    const handlePerRowsChange = (perPage, inPage)=>{
        props.historyFilter(perPage,inPage,'','');
      }
      const handlePageChange = inPage => {
          props.historyFilter(
              props.data.perPage,
              inPage,
              '',''
          )
      };
    return (
        console.log(t('Common.transferType')),
        <div className={style.tables}>
               <DataTable 
                    columns={Columns}
                    data={props.data.data}
                    totalRows={props.data.totalRows} 
                    pending={props.data.pending}
                    handlePerRowsChange={handlePerRowsChange}
                    handlePageChange={handlePageChange}
               />
           </div>
    )
}

export default EpinTransferhistory
 