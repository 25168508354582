import React from 'react';
import style from './board.module.scss';
import {BoardNode,TableNode} from './';
import {useSelector} from 'react-redux';

function BoardTree(props) {
    const cplan = useSelector(state=>state.ProjectConfig.mlmPlan);
    console.log(cplan)

    return (
        console.log(cplan),
        <div className={`${style.treeMain}`}  >
            {props.data&&
                     <div className={style.chart} style={props?.style}>
                     {
                         !props.table_status?
                   
                         <BoardNode 
                             {...props.data[0]}
                             depth={props.depth}
                             width={props.width}
                             currentdepth={0}
                             currentwidth={0}
                             toolTipConfig={props.toolTipConfig}
                         /> : 
                         <TableNode
                             {...props.data[0]}
                             depth={props.depth}
                             width={props.width}
                             currentdepth={props.depth}
                             currentwidth={props.width}
                             toolTipConfig={props.toolTipConfig}
                         />
                     }
                 </div>


            }
   
        </div>
    )
}

export default BoardTree
