import {combineReducers} from 'redux';
import LoggedReducer     from './isLogged';
import changeLangReducer from './changLang';
import changeCurrReducer from './currency';
import changeMenuReducer from './MenuReducer';
import NotificationReducer from './notificationReducer';
import MailReducer from './mail';
import ProjectReducer from './projectConfig';
import replicaLangReducer from './replicaLang';
const allReducer = combineReducers({
    isLogged    : LoggedReducer,
    Lang        : changeLangReducer,
    curr        : changeCurrReducer,
    Menu        : changeMenuReducer,
    Notification : NotificationReducer,
    Mail        : MailReducer,
    ProjectConfig   : ProjectReducer,
    repLang:replicaLangReducer
});

export default allReducer;