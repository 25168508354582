import React from 'react';
import style from './auth.module.scss';
import {useTranslation} from 'react-i18next';
const LoginButton = ({submit,...props}) => {
    const {t} = useTranslation();
    return (
        <div className={style.loginButton}>

            <button className={`${style.loginFormBtn}`} onClick={submit} disabled={props.disabled|| props.loader }> {props.loader ?
                               <i className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />:''} {t('Login.LoginBtn')}</button>
        </div>
    );
};

export default LoginButton;