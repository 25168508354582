import React from 'react';
import {Button} from 'react-bootstrap';
import style from './mail.module.scss';

function ContentHeader(props) {
    return (
        <div className={style.headerContent}>
            <div className={`${style.btnGroup}`}>
                {
                    !props.view?
                        <Button onClick={props.refersh}>
                            <i className='fa fa-refresh'></i>
                        </Button>:
                        <Button onClick={props.back}>
                            <i className='fa fa-long-arrow-left'></i>
                        </Button>
                }
            </div>
        </div>
    )
}

export default ContentHeader
