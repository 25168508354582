import {React} from 'react';
import {Form} from 'react-bootstrap';
import style from './Common.module.scss';
import {useTranslation} from 'react-i18next';
import {IMG_URL} from '../../../configuration/apiconfig'
//import { SelectionState } from 'draft-js';



function FormInputControl({children,...props}) {


    const {t} = useTranslation();
  
    return (
        <Form.Group className={`${style.formGroup} ${props.formClass}`}>
            {
                props.label&&
                <Form.Label className={props.isRequired | props.required && style.required} {...props.labelProps}>{props.label}</Form.Label>
            }
            <Form.Control
                className={`
                        ${props.inputClass}
                        ${props.inputProps.type !=='file' && style.formsInputField}
                        ${props.error && props.error.error &&!props.textSuccess ?`${style.FormsHasError} form-control is-invalid`: ""}
                    `}
                disabled={props.checkout}
                  
                { 
                    ...props.inputProps
                }
                />
                {children}
            <Form.Text className={`
                ${ props.error &&props.error.error
                    ?( !props.textSuccess&& style.isInvalid)
                    : "d-none"}
                `}>
                    {
                        props.textIcon&&
                        [
                            props.textSuccess?
                            <img src={`${IMG_URL}/public_html/images/accepted.png`} alt="" key="success" className={style.textSuccess}/>:
                            <i className="fa fa-times-circle" style={{color:'red'}} key="failiure"></i>
                        ]
                    }
                    &nbsp;
                {props.error&& t(props.error.error,props.error.field)}
            </Form.Text>
        </Form.Group>
    )
}

export default FormInputControl
