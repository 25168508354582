import React from 'react';
import {Card} from 'react-bootstrap';
import style from '../tools.module.scss';
import {useTranslation} from 'react-i18next';
import { IMG_URL } from '../../../../configuration/apiconfig';
import { CustomButton } from '../../common';
import {useHistory} from 'react-router-dom';
function EmailInvites(props) {
    const {t} = useTranslation();
    const history = useHistory()    
    const PreviewClick  = (data) =>{
        history.push({
            pathname : `/editInvite/${data.id}`,
            state : data
        })
    }
    //social email section
    const social_emails = props.social_emails.map((email, key) => {
        return (
            <div key={key} className={style.invitesEmailItem}>
                <div className={style.emailThumbnail}>
                    <img src={`${IMG_URL}/logos/Mail.png`}  alt="emailThumbnail" />
                </div>

                <div className={style.inviteItemContent}>
                    <h3 className={style.userName}>{email.subject}</h3>
                    <span>{t('Common.emailInvites')}</span>

                    <div className={`${style.textContent}`} dangerouslySetInnerHTML={{__html:email.content}}></div>
                    <CustomButton
                        variant="primary"
                        btnclass={style.previewBtn}
                        onClick={()=>PreviewClick(email)}
                    >
                        {t('Button.preview')}
                    </CustomButton>
                </div>
            </div>
        )
    });
    return (
        <Card>
            <Card.Body className={`${style.cardBody}`}>
                <div className={style.inviteContent}>
                    <legend>
                        <span>{t('Common.invites')}</span>
                    </legend>
                    {social_emails}
                    <legend>
                        <span>{t('Common.invitesHistory')}</span>
                    </legend>
                    {
                        props.inviteHistory.length===0?
                        <h4 className={`text-center`}>{t('Common.noDataRecordsToDisplay')}</h4>:
                        props.inviteHistory.map((history,index)=>(
                            <div key={index} className={style.invitesEmailItem}>
                                <div className={style.emailThumbnail}>
                                    <img src={`${IMG_URL}/uploads/images/logos/Mail.png`}  alt="emailThumbnail" />
                                </div>

                                <div className={style.inviteItemContent}>
                                    <h3 className={style.userName}>{history.subject}</h3>
                                    <span className={style.historyDate}>
                                        <i className={`fa fa-calendar ${style.textPrimary}`}></i>
                                        &nbsp;
                                        {history.date}
                                        &nbsp;
                                        <i className={`fa fa-envelope ${style.textSuccess}`}></i>
                                        &nbsp;
                                        {history.mail_id}
                                    </span>

                                    <div className={`${style.textContent}`} dangerouslySetInnerHTML={{__html:history.message}}></div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </Card.Body>
        </Card>
    )
}

export default EmailInvites
