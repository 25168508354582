import React,{useState,useEffect} from 'react'
import style from './step.module.scss';
import { Row, Col} from 'react-bootstrap'
import GenealogyService from '../../../../service/tree/genealogy';
import { ToolTips } from '../'
import { usePopperTooltip } from 'react-popper-tooltip';

function StepContent(props) {
    console.log("stepContent",props)
    const steps = Object.assign([], props.users).reverse();
    const step_length = steps.length;
    const [state, setState] = useState({
        loading: true,
        data: [],
        tooltipConfig: {},
        storeUrl: "",
        error: {
            show: false,
            message: ''
        },
        serachloading:false,
        resetloading:false,

    })
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip();
    const service = new GenealogyService()
    useEffect(()=>{
getlist()
    },[])
    const  getlist = (username = '', parentId = "") => {
        service.getTreelist(username, parentId).then(res => {
            if (res.status) {
                setState({
                    loading: false,
                    data: res.data.TreeData,
                    tooltipConfig: res.data.tooltip_config,
                    storeUrl: res.data.store_url
                });
            } else {
                //if the invalid access tocken
                if (res.error.code === 1002) {
                    props.history.push('/logout')
                } else if (res.error.code === 1043) {
                    props.history.push({
                        pathname: '/genealogyTree',
                        state: {
                            error: true,
                            message: 'InvalidUserName'
                        }
                    })
                }
            }
        })

    }

    
    let items = steps.map((users, index) => {
        if(users.length === 0) { //Show Empty step
            return (
                <Row key={index} style={{width: `calc(${index+1}*100%/${step_length})`}}>
                    <Col className={style.node} style={{width: `calc(100% / ${step_length})`}}>
                        <div className={style.nodeItem}></div>
                    </Col>
                </Row>
            )
        }

        users = Object.assign([], users);
        let users_list = users.map((user, key) => {
            console.log(key,"key key",user,"user data",state.data.user_name,"name")
            return (
                <Col key={key} className={style.node} style={{width: `calc(100% / ${step_length})`}}>
                    <div className={style.nodeItem} ref={setTriggerRef}>
                        {user.user_name}
                        {visible&&state.data.user_name!=='NA' && (
                                    <div
                                    ref={setTooltipRef}
                                    {...getTooltipProps({ className: `tooltip-container` })}
                                    >
                                        <div {...getArrowProps({ className: ` ${style.arrow}` })} />
                                            <ToolTips 
                                            config={state.tooltipConfig}
                                                {...state.data[key]}
                                            />
                                        </div>
                                    )}
                    </div>
                </Col>
            )
        }) 

        return (
            <Row key={index} style={{width: `calc(${index+1}*100%/${step_length})`}}>
                {users_list}
            </Row>
        )
    })
    let step_counts = steps.map((users, index) => {
        return <div className={style.stepId} key={index}>
            step - {steps.length - index-1}
        </div>
    })

    return (
       <div className={style.stepViewContainer}>
            <Row>
                <Col xs={11}>
                    {items}
                    
                </Col>
                <Col xs={1}>
                    {step_counts}
                </Col>
            </Row>
       </div> 
    )
}

export default StepContent
