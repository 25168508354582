
import React, { useState, useEffect } from "react";
import style from './dashboard.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import TilesFilter from './TilesFilter';
import Chart from 'react-google-charts';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'


// function mapStateToProps(state) {

//     const { Language } =state.Lang.currentLang
//   
//     return { Language:  Language}

//   }

function Charts(props) {


    const Lang = useSelector(state => state.Lang);



    const [chart, setChart] = useState([])

    const { t } = useTranslation()

    const [filterData, setFilterData] = useState([
        {
            code: 'year',
        },
        {
            code: 'month',
        },
        {
            code: 'day',
        }
    ])

    let formatLabels = [];


    const convertData = () => {
        formatLabels.push('Month')
        props.data && props.data.code.map((item) => {

            formatLabels.push(t('Common.' + item))
        })


    };


    useEffect(() => {

        const ChartData = [];

        convertData();
        if (props.data) {
            ChartData.push(formatLabels)

            props.data.chart.map((item) => {
                ChartData.push(item)
            })
            setChart(ChartData);
        }
    }, [props.data, Lang])

    const LineChartOptions = {
        allowHtml: true,
        hAxis: {
            title: '',
        },
        vAxis: {
            title: '',
        },

        colors: props.data && props.data.colors,
        series: {
            curveType: 'function',

        },
        legend: { position: 'bottom' },
        chartArea: { width: '100%', left: 40 },


    }






    return (


        <div className={style.dashboard_joining_sec}>
            <div className={style.dashboard_pannel_box}>


                {props.lodervalue &&
                    <div className={style.dashboard_newsec_head}> <Skeleton width={100} count={1} /></div>
                }



                {chart && chart.length > 0 &&
                    <>
                        <TilesFilter
                            items={filterData}
                            onChange={props.filterChange} />
                        <div className={style.dashboard_newsec_head}>{t('Common.joinings')}</div>
                        <div className={style.dashboard_newsec_grph_sec} style={{ overflow: 'hidden' }}>

                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="LineChart"
                                loader={<div>Loading Chart</div>}
                                data={chart}
                                options={LineChartOptions}
                                rootProps={{ 'data-testid': '2' }}
                            />

                        </div>
                    </>

                }
            </div>
        </div>





    );
};





export default Charts;

// export default
// withTranslation()
// (connect(mapStateToProps)(withRouter(Charts)));