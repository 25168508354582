import React from 'react';
import style from './mail.module.scss';
import {Badge, Button,Nav,Navbar} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
function MailSidebar(props) {
    const {t} = useTranslation();
    const history = useHistory();
    const changeTab = (menu) =>{
        if(menu === 'compose'){
            var element = document.getElementById(props.selecetedKey);
            element && element.classList.remove("active");
        }else{
            element = document.getElementById(props.selecetedKey);
            element && element.classList.add("active");
        }
        history.push(`/mailbox/${menu}`);
    }
    return (
        <Navbar collapseOnSelect expand="lg"className={style.leftPanel}>
            <div className={style.wrapper}>
                <Button className={style.composeButton} onClick={()=>changeTab('compose')}>
                    {t('Button.compose')}
                </Button>
            </div>
            <Navbar.Toggle aria-controls="mail-box" />
            <Navbar.Collapse id="mail-box">
                    <Nav className={`d-block ${style.navigation} mailbox-nav`} variant="pills" defaultActiveKey={props.selecetedKey} onSelect={props.changeTab}>
                        <Nav.Link eventKey="inbox" onClick={()=>changeTab('inbox')} id="inbox">
                            <span>
                                <i className="fa fa-inbox"></i>
                                {t('Button.inbox')}
                                {
                                    props.unreadCount>0&&
                                    <Badge className={style.inboxBadge}>
                                        {props.unreadCount}
                                    </Badge>
                                }
                            </span>
                        </Nav.Link>
                        <Nav.Link eventKey="send" onClick={()=>changeTab('send')} id="send">
                            <span>
                                <i className="fa fa-envelope-o"></i>
                                {t('Button.send')}
                            </span>
                        </Nav.Link>
                    </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default MailSidebar
