import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from 'react-bootstrap';
import style from './payment.module.scss';

function FreeJoin(props) {
    const {t} = useTranslation();
    return (
        <div className={style.MianContent}>
            <div>
                <pre className={`alert alert-info`}>{t('Common.clickFinish')}</pre>
                <div className={style.Buttons}>
                    {
                        props.prevButtonClick&&
                        <Button color="primary" onClick={props.prevButtonClick} className={`btn-pill  ${style.PrevButton}`} style={{marginRight: '20px'}} >
                            <i className="fa fa-chevron-left" />
                            
                            {t('profile.prev')} &nbsp;
                        </Button>
                    }
                    <Button color="primary" className={`btn-pill ${style.NextButton}`} type="submit" style={{marginRight: '20px'}}>
                        {t('Button.finish') } &nbsp;
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default FreeJoin
