import React from 'react';
import {useTranslation} from 'react-i18next';
import style from './profile.module.scss';

function FormHead(props) {
    const {t} = useTranslation();
    const buttonClick = () =>{
        props.change(props.status)
    }
    return (
        <div className={style.FormHead}>
            <h3 className={style.headeText}>{t('Common.'+props.title)}</h3>
            <button 
                className={`${style.editButton}`} 
                onClick={buttonClick}
                hidden={!props.show}
            >
                <i className="fa fa-pencil"></i>
            </button>
        </div>
    )
}

export default FormHead
