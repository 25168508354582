import React from 'react'
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import convert from 'htmr';
import style from './mail.module.scss'




const ComposeText = (props) =>{

    const { t } = props;
    
    return(
        <Form.Group>
            <Form.Label className={style.required}>{t('Common.mailContent')}</Form.Label>
            <div>
                <Editor
                    editorState={props.editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName={style.Editor}
                    onEditorStateChange={props.editorChange}
                    placeholder={t('Common.typesomething')}
                />
            </div>

            <Form.Text className={`
                ${props.state.FormError.mailContent
                    ? style.isInvalid
                    : "d-none"}
                `} >
                
                {t(props.state.FormError.mailContent, { field: 'message' })}
            </Form.Text>
        </Form.Group>
    )
}

export default withTranslation()(ComposeText)