import React from 'react'
import style from './Common.module.scss';
function CustomButton({children,...props}) {
    return (
        <button   className={`${style.customBtn}  ${style[props.variant]} ${props.btnclass}`} {...props}>
            {children}
        </button>
    )
}

export default CustomButton
