import React, { useEffect } from 'react';
import style from './replica.module.scss';
import { Row, Col, Container, Navbar } from 'react-bootstrap';
import WOW from 'wowjs';
import { useTranslation, withTranslation } from 'react-i18next';



function Policy(props) {
    const { t } = useTranslation();
    return (
        <div className="container">
        <section  className="plan_cnt_sec" style={{marginTop:"90px"}}>
            <h1> {t('Replica.privacy')}</h1>
             <div className="container">
            <div    dangerouslySetInnerHTML={{__html:props.data.policy_data.content?.policy&&props.data.policy_data.content?.policy}}></div>
         
            </div>
         
        </section>
        </div>

    )
}

export default Policy