import {BASE_URL,DEFAULT_KEY} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';



export default class AppService{
    key =localStorage.getItem('apiKey')
    async getApiCall(url){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+url, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //change lang
    async setLanguage(id){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/set_default_language', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : DEFAULT_KEY,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(id)
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    ///change the currency
    async changecurrency(currency){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'home/set_default_currency', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(currency)
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
}