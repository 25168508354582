import React from 'react';
import {useTranslation} from 'react-i18next';
import {Dropdown,Button,ButtonGroup} from 'react-bootstrap';
import style from './Common.module.scss';

function PageTitle({title,buttonOn,group,AddonIcon,icon}) {

    const {t} = useTranslation();
    return (
        <div className= { buttonOn ? `${style.pageTitle} animate__animated animate__fadeInDown` : `${style.noneeed}` } >
          
            <span className={style.page_title_text}>{t('Sidemenu.'+title)}</span>
           
            {
                buttonOn &&
                [
                    AddonIcon?
                    <div className={style.ModalButtonGroup} key="singleButton">
                        <Button className={`${style.MainButton} ${style.btnAddon} ${style.btnInfo}`} onClick={group[0].click}>
                            {
                                icon &&
                                <i className={icon}></i>
                            }
                            {
                                group[0] &&
                                t('Button.'+group[0].name)
                            }
                        </Button>
                        
                    </div>
                    :
                    <Dropdown as={ButtonGroup} bsPrefix={style.ModalButtonGroup} key="buttonGroup">
                        <Button variant="info" className={style.MainButton} onClick={group[0].click} >{t('Button.'+group[0].name)}</Button>

                        { 
                            group.length>1 &&
                            <>
                                <Dropdown.Toggle split variant="info" className={style.splitIcon} />

                                <Dropdown.Menu>
                                    {
                                        group.map((item,index)=>{
                                            if(index > 0)
                                                return <Dropdown.Item onClick={item.click} key={index}>{t('Button.'+item.name)}</Dropdown.Item>
                                            return null
                                            })
                                    }
                                </Dropdown.Menu>
                            </>
                        }
                    </Dropdown>
                ]
            }
        </div>
    )

    // return(
    //     <div style={{'position':'absolute','marginTop':'2% !important','marginBottom':'2%'}}>

    //     </div>
    // )
}

export default PageTitle
