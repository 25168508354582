import React from 'react';
import {ListGroup,Form, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import style from './mail.module.scss';
import {MailType,ComposeText} from './'
function ComposeMail(props) {
  console.log("com[opsemail....",props)
    const {t} = useTranslation();
    return (
            <ListGroup className={style.composeMailContent}>
                <ListGroup.Item className={style.listItems}>
                    <div className={style.content}>
                        <Form>
                            {
                                props.selectKey === 'reply'&&
                                <Form.Group controlId="toUser">
                                    <Form.Label className={style.required}>{t('Common.replyTo')}</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        className={style.formControl}
                                        name="toUser"
                                        value={props.state.toUser??''}
                                        disabled={true}
                                    />
                                </Form.Group>
                            }
                            <Form.Group controlId="subject">
                                <Form.Label className={style.required}>{t('Common.subject')}</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    className={style.formControl}
                                    name="subject"
                                    onChange={props.changeHandler}
                                    value={props.state.subject}
                                    />
                                    <Form.Text className={`
                                        ${props.state.FormError.subject
                                            ? style.isInvalid
                                            : "d-none"}
                                        `}>
                                            {t(props.state.FormError.subject,{field:'subject'})}
                                    </Form.Text>
                            </Form.Group>
                            {
                                props.selectKey !== 'reply'&&
                                <MailType 
                                    {...props}
                                />
                            }
                            {
                                (props.selectKey !== 'reply'&&props.state.type==='individual')&&
                                <Form.Group>
                                    <Form.Control as="select" name="member" onChange={props.changeHandler} value={props.state.member}>
                                        <option value="">{t('Common.selectOneMember')}</option>
                                        {
                                            props.composeData&&
                                            props.composeData.downline_users.map((list,index)=>(
                                                <option value={list.id} key={index}>{list.value}</option>
                                            ))
                                        }
                                    </Form.Control>
                                    <Form.Text className={`
                                        ${props.state.FormError.member
                                            ? style.isInvalid
                                            : "d-none"}
                                        `}>
                                            {t(props.state.FormError.member,{field:'member'})}
                                    </Form.Text>
                                </Form.Group>
                            }
                            {
                                (props.selectKey !== 'reply'&&props.state.type==='externalMail')&&
                                <>
                                    <Form.Group>
                                        <Form.Control 
                                            type="text" 
                                            className={style.formControl}
                                            name="email"
                                            onChange={props.changeHandler}
                                            value={props.state.fromMail}
                                            disabled
                                            />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control 
                                            type="text" 
                                            className={style.formControl}
                                            name="tomail"
                                            onChange={props.changeHandler}
                                            value={props.state.tomail}
                                            placeholder={t('Common.to')}
                                            />
                                            <Form.Text className={`
                                                ${props.state.FormError.subject
                                                    ? style.isInvalid
                                                    : "d-none"}
                                                `}>
                                                    {t(props.state.FormError.tomail,{field:'tomail'})}
                                            </Form.Text>
                                    </Form.Group>
                                    
                                </>
                            }
                            <ComposeText 
                                // editorChange={props.editorChange}
                                {...props}
                            />
                            <Button className={style.sendButton} onClick={props.messagesend}>
                                {t('Button.sendMessage')}
                            </Button>
                        </Form>
                    </div>
                </ListGroup.Item>
            </ListGroup>
    )
}

export default ComposeMail
