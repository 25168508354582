import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class EpinService{
    key =localStorage.getItem('apiKey')
    async Epin(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/epin_tile', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //get the epin list table
    async EpinList(start=0,length=10,direction="",order="",epin='',amount='',status='active'){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/epin_list?start='+start+'&length='+length+'&direction='+direction+'&order='+order+'&epins[]='+epin+'&amounts[]='+amount+'&status='+status, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //pending e pin request
    async PendingEpinList(start=0,length=10,direction="",order=""){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/epin_pending_requests?start='+start+'&length='+length+'&direction='+direction+'&order='+order, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    //search epin
    async searchepin(keyword){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/epin_search?keyword='+keyword, {
                method: 'GET',
                headers : {
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    'Accept-Language': '',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }


    //refund
    async refundAmount(formData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/epin_refund', {
                method: 'POST',
                headers : {
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    'Accept-Language': '',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },body: formData
                })
                .then((response) => {
                    return response;
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //get the transfer history
    async transferHistory(start=0,length=10,direction="",order="",startDate='',endDate=''){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/epin_transfer_history?start='+start+'&length='+length+'&direction='+direction+'&order='+order+'&start_date='+startDate+'&end_date='+endDate, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //get the epin amount
    async getEpinamounts(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/epin_amounts', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    //epin purchase post 
    async epinPurchase(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/epin_purchase', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body : JSON.stringify(data)
                })
                .then((response) => {
                    if(response.status===204){
                        return {
                            status : true
                        }
                    }
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //request epin
    async epinRequest(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/request_epin', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body : JSON.stringify(data)
                })
                .then((response) => {
                    if(response.status===204){
                        return {
                            status : true
                        }
                    }
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //epin number get 
    async getepinNumbers(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/epin_numbers', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //transfer epin
    async epinTransfer(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'epin/epin_transfer', {
                method: 'POST',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body : JSON.stringify(data)
                })
                .then((response) => {
                    if(response.status===204){
                        return {
                            status : true
                        }
                    }
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
}