import React from 'react';
// import {Tabs,Tab} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import style from './epin.module.scss';
import Tabs from 'react-responsive-tabs';
import {EpinList,RequestPendingList,EpinTransferhistory} from './'
function Content(props) {
    const {t}  = useTranslation();
    const tabs = ['epinList','pending_epin','EpinTransferHistory'];
    const getTabs = () =>{
        return tabs.map((tab, index) => ({
            title: t('Epin.'+tab),
            getContent:() => getContent(tab),
            /* Optional parameters */
            key: tab,
            tabClassName: style.TabClass,
            panelClassName: style.TabPanel,
            // tabsWrapperClass : 'safdsadfads'
        }));
    }
    //get content
    const getContent = (contentId) =>{
        if(contentId === 'epinList' ){
            return <div className={style.dataTable}>
                    <EpinList
                        data={props.tableData.EpinList}
                        listFilter={props.filterHandler.list}
                        loader = {props.loader}
                        search={props.search}
                        reset={props.reset}
                        getEpinList={props.getEpinList}
                    />
                </div>
        }else if(contentId === 'pending_epin'){
            return <div className={style.dataTable}>
                    <RequestPendingList 
                        data={props.tableData.PendingList}
                        pendingFilter={props.filterHandler.pending}
                    />
                </div>
        }else if(contentId === 'EpinTransferHistory'){
            return <div className={style.dataTable}>                          
                    <EpinTransferhistory 
                        data={props.tableData.TransferHistory}
                        historyFilter={props.filterHandler.history}
                    />
                </div>

        }
    }
    return (
        <div className={style.summary}>
            <div className={style.tabContent}>
                <Tabs items={getTabs()} transform={true} transformWidth={734} onChange={props.tabChangeHandler}/>
            </div>
        </div>
    )
} 

export default Content
