// let currentLang=localStorage.getItem('i18nextLng');
let language = {
    currentLang : localStorage.getItem('i18nextLng')??'en',
    languages   : []
}

const replicaLangReducer = (state =  language , action)=>{
    console.log("replicaLangReducer",action)
    switch (action.type) {
        case 'REPLICA_CHANGE_LANG':
            return { ...state, currentLang: action.repLang };//change language
        case 'REPLICA_ADD_Lang' :
            return {...state,languages : action.repLang }
        default:
            return state;
    }
}

export default replicaLangReducer;