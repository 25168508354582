import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class EwalletService{
    key =localStorage.getItem('apiKey')
    async Ewallet(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'ewallet/ewallet_tile', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    async statement(start=0,length=10){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'ewallet/ewallet_statement_table?length='+length+'&start='+start+'', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    async history(start=0,length=10,direction="",startDate="",endDate="",keys="",order=""){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'ewallet/ewallet_history_table?direction='+direction+'&length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate+'&order='+keys+'&type='+order, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }
    async wallet(start,length){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'ewallet/purchase_wallet_table?length='+length+'&start='+start+'', {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    async earnigs(start=0,length=10,direction="",startDate="",endDate="",keys="",order=""){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'ewallet/user_earnings_table?direction='+direction+'&length='+length+'&start='+start+'&start_date='+startDate+'&end_date='+endDate+'&order='+keys+'&'+order, {
                method: 'GET',
                headers : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //fund Transfer post 
    async fundTransfer(data){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'ewallet/fund_transfer', {
                method: 'POST',
                headers : {
                    // 'Accept'        : 'application/json',
                    // 'Content-Type'  : 'application/json',
                    'Accept-Language': '',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                }, 
                body: data
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    async exportData(startDate="",endDate="",keys="",type=""){
        const response = await fetch(BASE_URL+'ewallet/earnings_export_data?type='+type+'&start_date='+startDate+'&end_date='+endDate+'&'+keys,{
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        });
        return response.json();
    }
}