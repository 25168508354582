import React,{useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
//import DataTableExtensions from 'react-data-table-component-extensions';
import "react-data-table-component-extensions/dist/index.css";
//service 
import {CurrencyDecimalFormat} from '../../helper';
import style from './ewallet.module.scss';
import {Filter} from './';
import moment from 'moment';



function EarningsTable({ContentData,paginationchange, exportData,loader}) {



    
    const {t}  = useTranslation();
    const Currency       = useSelector(state => state.curr);

   
    const columns = [
        {
          name: t('Common.category').toUpperCase(),
          selector:row => row.category,
          width : "30%"
        },
        {
            name: t('Common.totalAmount').toUpperCase(),
            selector: row => row.total_amount,
           
            cell: (row) => <span className={`${style.credit} ${style.badge}`}> {Currency.currentCurr} {(row.total_amount)}</span>
        },
        {
          name: t('Common.tax').toUpperCase(),
          selector: row => row.tax,
          cell: (row) => <span className={`${style.balanceAmount} ${style.badge}`}>{Currency.currentCurr} {(row.tax)}</span>
        },
        {
            name: t('Common.serviceChange').toUpperCase(),
            selector: row => row.serviceChange,
            cell: (row) => <span className={`${style.balanceAmount} ${style.badge}`}>{Currency.currentCurr}   {(row.serviceChange)} </span>
        },
        {
            name: t('Common.amountPayable').toUpperCase(),
            selector: row => row.amount_payable,
            cell: (row) => <span className={`${style.balanceAmount} ${style.badge}`}>{Currency.currentCurr}  {(row.amount_payable)}  </span>
        },
        {
            name: t('Common.transactionDate').toUpperCase(),
            selector: row => row.transaction_date,
            sortable: true
        },
      ];
    const [state, setState] = useState({ data: [] });
    const [date,SetDate] = useState({
        start:moment().startOf('month').format('MMMM D, YYYY'),
        end: moment().format('MMMM D, YYYY')})

    const {data} = state;
   
   
   
    useEffect(()=>{
        if(ContentData.data){
            let NewData = ContentData.data.map((resData)=>{
               
                return {
                    // classnameamount : style.credit,
                    total_amount : resData.total_amount,
                    category : resData.category,
                    transaction_date : resData.transaction_date,
                    tax : resData.tax,
                    serviceChange : resData.service_charge,
                    amount_payable : resData.amount_payable
                }
            })
            setState(prev => ({
                ...prev,
                data:  NewData
            }));
        }
    },[ContentData]);
    const handlePerRowsChange = (perPage, inPage) => {
        paginationchange(
            inPage,
            perPage,
            date.start,date.end,'','',''
        )
    };
    const handlePageChange = inPage => {



        paginationchange(
            inPage,
            ContentData.perPage,
            date.start,date.end,'','',''
        )
    };
    const handleOnSort = ({ selector },sortDirection) => {
        paginationchange(
            ContentData.inPage,
            ContentData.perPage,
            '',
            '',
            sortDirection,
            selector,
            '',
            )
    };
    const filterHandlet = (start, end,type='') => {
        SetDate(prev => ({
            ...prev,
            start : start,
            end   :  end,
          }));
        let keys=type.map((key,idx)=>{
            return  'categories[' + idx + ']=' + key.value;
        }).join('&');

        
        loader.EarningsTable.search = true

       
        paginationchange(
            ContentData.inPage,
            ContentData.perPage,
            start,
            end,
            '',
            '',
            keys,
            )

            
    }




    //reset handler
    const resetData = () =>{
        console.log("This is getting called in resetdata")

        SetDate(prev => ({
            ...prev,
            start:moment().startOf('month').format('MMMM D, YYYY'),
            end: moment().format('MMMM D, YYYY')
        }));

        console.log("This is againgetting called in resetdata")
        

        loader.EarningsTable.reset = true
        
            
        paginationchange(
            ContentData.inPage,
            ContentData.perPage,
            moment().startOf('month').format('MMMM D, YYYY'),
            moment().format('MMMM D, YYYY'),
            '',
            '',
            '',
            )
    }
    return (

        <>
            {
                ContentData.category.length!==0 &&
                <Filter 
                    filterHandler={filterHandlet}
                    type={ContentData.category}  
                    resetHandler={resetData}
                    isSingle={false}
                    exports={true}
                    exportData={exportData}
                    ContentData={ContentData}
                    loader={loader}
                />
            }
         
            {/* <DataTableExtensions
                filter={false}
                exportHeaders={true}
                columns={columns}
                data={data}
            > */}

            
                <DataTable
                    columns={columns}
                    data={data}
                    highlightOnHover
                    progressPending={ContentData.pending}
                    progressComponent={<div>Loading</div>}
                    persistTableHead
                    noHeader
                    pagination
                    paginationServer
                    responsive
                    paginationTotalRows={ContentData.totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noDataComponent={t('Common.noDataRecordsToDisplay')}
                    onSort={handleOnSort}
                    />
            {/* </DataTableExtensions> */}

          
        </>
    )
}

export default EarningsTable
