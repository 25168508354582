import React, {useRef} from 'react';
import { Tab,Tabs} from 'react-bootstrap'; 
import {useTranslation} from 'react-i18next';
//style 
import style from './payout.module.scss';
import {Button} from 'react-bootstrap';
import ReactToPrint from "react-to-print";


import PayoutContentPrintView from'./PayoutContentPrintView'


//compoenents
import { DropDown,PendingTable,PaidTable,ApprovedTable,RejectedTable} from './';

function Content(props) {
    const {t}  = useTranslation(); 
    const componentRef = useRef();
    return (
        <div className={style.summary}>
            <div className={style.tabContent}>
            <Tabs defaultActiveKey={`statement`} id="uncontrolled-tab-example" transition={false}>
                <Tab eventKey="statement" title={t('Common.payoutSummary')}>
                    <div className={style.dataTable}>
                        <DropDown
                            handler={props.changeHandler}
                            loader={props.loader}
                        />
                        {
                         <div className={style.exportButton} >
                             
                             <Button onClick={()=>{props.exportData('excel')}}>
                             {       props.loader.excel === true ?
                            <i className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" , backgroundColor: "transparent"}}
                            /> : <i className="fa fa-file-excel-o"></i>
}
                            
                            {t('Button.excel')}
                               </Button>
                                     <Button onClick={()=>{props.exportData('csv')}}>
                                     {       props.loader.csv?
                            <i className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" , backgroundColor: "transparent"}}
                            /> : <i className="fa fa-file-text-o"></i>
}
                            
                            {t('Button.CSV')}
                               </Button>
                            <ReactToPrint
                            trigger={() => <Button><i className="fa fa-print"></i>{t('Button.print')}</Button>}
                             content={() => componentRef.current}
                                             />

                                     <div style={{ display: "none" }}>
                                    <PayoutContentPrintView ref = {componentRef} ContentData={props.tableData} />
                                 </div>
      
      
                      </div>
                             }
                        {
                            props.tableData.key === 'pending' && <PendingTable 
                                ContentData={props.tableData.pending}
                                paginationchange={props.paginationHandler.pending}
                                cancelPopupHandler={props.paginationHandler.cancel}
                                onSubmit={props.paginationHandler.submit}
                            />
                        }
                        {
                            props.tableData.key === 'approved' && <ApprovedTable 
                                ContentData={props.tableData.approved}
                                paginationchange={props.paginationHandler.approved}
                            />
                        }
                        {
                            props.tableData.key === 'paid' && <PaidTable 
                                ContentData={props.tableData.paid}
                                paginationchange={props.paginationHandler.paid}
                            />
                        }
                        {
                            props.tableData.key === 'rejected' && <RejectedTable 
                                ContentData={props.tableData.rejected}
                                paginationchange={props.paginationHandler.rejected}
                            />
                        }
                    </div>
                </Tab>
            </Tabs>
            </div>
        </div>
    )
}

export default Content
