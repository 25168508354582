import React, { useState } from 'react'
import {Card,Row,Col, Form, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import style from './tree.module.scss';
function TitleHeaders(props) {
    const {t} = useTranslation();
    const [level,setLevel] = useState('all');
    const [loaders,setLoaders] = useState({
      resetloader:false,
      searchloader:false
    });

    //change level
    const changeLevel = (level)=>{

          setLevel(level.target.value)
           
       
    }
    
    const reset = () =>{

        setLoaders(prev=>({
            ...prev,
            resetloader:true
        }));
        setTimeout(() => {
            setLevel('all');
            props.reset();
            setLoaders(prev=>({
                ...prev,
                resetloader:false
            }));

          }, 3000);
     
    }
    return (
        <Card className={style.titlepanel}>
            <Card.Body className={style.panelBody}>
                <Row>
                    <Col md={6} sm={12}>
                        <div className={`${style.count} ${style.colorPurple}`}>
                            <div className={`${style.text} ${style.textPurple}`}>
                                {t('Tree.'+props.tilesvalue)}
                            </div>
                            <div className={`${style.textPurple} ${style.countText}`}>
                                {props?props.count:0}
                            </div>
                        </div>
                        <div className={`${style.count} ${style.colorGreen}`}>
                            <div className={`${style.text} ${style.textGreen}`}>
                                {t('Tree.totalLevels')}
                            </div>
                            <div className={`${style.textGreen} ${style.countText}`}>
                                {props?props.level.length:0}
                            </div>
                        </div>
                    </Col>
                    <Col md={6} sm={12}>
                        <div className={style.searchRight}>
                            <div className={style.paddingSmall}>
                                <Form.Group className={style.formGroup}>
                                    <Form.Label>
                                        {t('Common.level')}
                                    </Form.Label>
                                    <Form.Control as="select" className={style.dropDownSelect} onChange={changeLevel} value={level}>
                                        <option value='all' >{t('Common.all')}</option>
                                        {
                                            props&&
                                                props.level.map((items)=>(
                                                <option value={items} key={items}>{items}</option>
                                            ))
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </div>
                            <div className={style.paddingSmall}>
                                <Form.Group className={style.formGroup}>
                                
                                    <Button className={`${style.Button} ${style.BtnPrimary}`} disabled={props.searchloader} onClick={()=>props.searchLevel(level)}>
                                    {props.searchloader ?
                                    <i className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    /> : ''}
                                        {t('Button.search')}
                                    </Button>
                                    <Button className={`${style.Button} ${style.BtnInfo}`}  disabled={loaders.resetloader} onClick={reset}>
                                    {loaders.resetloader ?
                                    <i className="fa fa-refresh fa-spin"
                                        style={{ marginRight: "5px" }}
                                    /> : ''}
                                    
                                        {t('Button.reset')}
                                    </Button>
                                </Form.Group>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default TitleHeaders
