import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';


export default class RegisterService{
    key =localStorage.getItem('apiKey')

    //get the registration details
    async RegisterDetails(){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'api_register/register', {     //url
                method: 'GET',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //check the sponsor username validation 
    async validateSponsorUserName(sponsorData){
        return new Promise((resolve, reject) =>{
            fetch(BASE_URL+'api_register/validate_username', {     //url
                method: 'POST',                 //method
                headers : {                     //passing header 
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json',
                    'api-key'       : this.key,
                    'access-token'  : UserStore.key
                },
                body: JSON.stringify(sponsorData)  //convert to json
                })
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    }

    //check the leg availability
    async checklegAvailability(legDetails){
        const response = await fetch(BASE_URL+'api_register/check_leg_availability', {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body: JSON.stringify(legDetails)  //convert to json
        })
        const legStatus = await response.json();
        return legStatus;
    }

    //chekc the username
    async checkUsename(username){
        const response = await fetch(BASE_URL+'api_register/check_username', {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body: JSON.stringify(username)  //convert to json
        })
        const legStatus = await response.json();
        return legStatus;
    }

    //register submit
    async regsiterSubmit(formData){
        const response = await fetch(BASE_URL+'api_register/register_submit', {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            },
            body: JSON.stringify(formData)  //convert to json
        })
        console.log(response)
        console.log(typeof(response))
        const legStatus = await response.json();
        return legStatus;
    }

    //register Summary
    async registerSummary(id=''){
        const response = await fetch(BASE_URL+'api_register/registration_preview?username='+id, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const legStatus = await response.json();
        return legStatus;
    }
    async checkPlacemntLegAvailability(username,position){
        const response = await fetch(BASE_URL+'api_register/check_leg_availability?placement_user_name='+username+'&position='+position, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                // 'Accept'        : 'application/json',
                // 'Content-Type'  : 'application/json',
                'api-key'       : this.key,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
}