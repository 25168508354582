import React from 'react';
import {Form,InputGroup} from 'react-bootstrap';
import style from './epin.module.scss';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import DatePickers from '../common/Datepickers';
function FormControls(props) {
    const {t} = useTranslation();
    const Currency  = useSelector(state=>state.curr);
    const Components = type =>{
        if(type === 'select'){
                    return <Form.Control 
                    as="select"
                    disabled={!props.data.isEditable}
                    className={`${style.Forms}`}
                    onChange={ props.change}
                    value={props.data.value ?? ''}
                    name={props.data.code}
                    >
                        <option>{t('registration.selectOption',{field:props.data.code})}</option>
                    {
                        props.data.options.map((option,index)=>{
                            console.log(option)
                            if(props.data.code === 'amount'){
                                return <option key={index} value={option.id}>{Currency.currentCurr}{(Currency.value * option.value).toFixed(Currency.precision)}</option>
                            }else{
                                return <option value={option.id} key={index}>{option.value}</option>
                            }
                            // return null
                            // return <option key={index} value={option.id}>{t(option.amount)}</option>
                        })
                    }
                    
                </Form.Control>
        }else if(type === 'date'){
            return <DatePickers 
                    disabled={props.data.isEditable}
                    start={props.data.value}
                    datechange={props.datechange}
                    id={props.data.code}
            />
        }else{
            return <Form.Control 
                        id={props.data.code}
                        type={type} 
                        name={props.data.code}
                        value={props.data.value??''}
                        disabled={props.data.code=='currentBalance'?!props.isEditable:props.isEditable} 
                        className={`
                            ${style.loginForms}
                            ${props.data.error ?`${style.loginFormsHasError}form-control is-invalid`: ""}
                            `} 
                        onChange={props.change}
                        placeholder={t('Common.'+props.data.code)}
                    />
        }
    }

    return (
        <Form.Group >
            <Form.Label htmlFor={props.data.code} className={props.data.required && style.required}>
                {t('Common.'+props.data.code)}
            </Form.Label>
            <InputGroup className="mb-2">
                {
                    props.data.prepend &&
                    <InputGroup.Prepend>
                        <InputGroup.Text>{Currency.currentCurr}</InputGroup.Text>
                    </InputGroup.Prepend>
                }
                {Components(props.data.type)}
            </InputGroup>
            {/* error message */}
            <Form.Text className={`
                ${props.data.error
                    ? style.isInvalid
                    : "d-none"}
                `}>
                    {t(props.data.error,props.data.errorField)}
            </Form.Text>
        </Form.Group>
    )
}

export default FormControls
