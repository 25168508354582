import {React,useEffect,useState} from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from './dashboard.module.scss';
import { Link } from 'react-router-dom';
import Links from './Links';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import CountdownTimer from '../common/CountdownTimer';
import { useCountdown } from '../common/useCountdown';
function Profiles(props) {
  


    const [state, setState] = useState({
        sevendaysinseconds: 7 * 24 * 60 * 60 * 1000,
        joineddatenowinseconds:new Date(props.profile&&props.profile.join_date).getTime(),
        loading: props.lodervalue,
        dateTimeAftersevendays:''
       
    })

    useEffect(() => {
        if(!state.loading){
            setState(prevstate => ({
                ...prevstate,
                dateTimeAftersevendays:state.sevendaysinseconds+state.joineddatenowinseconds
            }))
        }
      
      }, []);


    const { t } = useTranslation()

    const [days, hours, minutes, seconds] = useCountdown(new Date(state.sevendaysinseconds+new Date(props.profile&&props.profile.join_date).getTime()));
    return (
        <>

{days + hours + minutes + seconds >0 && !props.startup_bonus_done&&
                      
                      <div className={`${style.profile_other_box} border-0`}>
                          <div className={style.profile_other_box_cnt}>
                              <span>
                              <CountdownTimer targetDate={new Date(state.sevendaysinseconds+new Date(props.profile&&props.profile.join_date).getTime())} />
                              </span>
                              <p><strong className={style.cnt_strng}>{t('Common.notice')}</strong></p>
                          </div>
                      </div>
                 
                   }
            <div className={style.dashboard_pannel_box}>


          


                {props.lodervalue &&
                    <Row>
                        <Col xl={4} lg={4} md={12}>

                            <div className={style.profile_main_sec}>
                                <div className={style.profile_main_img_bx}>
                                    <Skeleton circle={true} height={90} width={90} />
                                </div>
                                <div className={style.profile_main_txt_sc}>
                                    <div>
                                        <Skeleton width={170} count={1} />
                                    </div>
                                    <div>
                                        <Skeleton width={100} count={1} />
                                    </div>
                                    <div>
                                        <Skeleton width={120} height={30} count={1} borderRadius={50} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xl={4} lg={4} md={12}>
                            <div className={style.profile_soc_share_sec}>
                                <div className={style.profilesoc_share_bx_hd}><Skeleton width={100} /></div>
                                <div className={style.profilesoc_share_bx_row}>

                                    <div className={style.profilesoc_share_bx_ico}>
                                        {[1, 2, 3, 4].map((items, index) => {
                                            return (
                                                <span style={{ paddingLeft: '3px', paddingTop: '10px' }}>
                                                    <Skeleton circle={true} height={30} width={30} />
                                                </span>

                                            )
                                        })}
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col xl={2} lg={2} md={6}>
                            <div className={style.profile_other_box}>
                                <div className={style.profile_other_box_cnt}>
                                    <Skeleton width={100} />
                                </div>
                                <div className={style.profile_other_box_cnt}>
                                    <Skeleton width={100} />
                                </div>


                            </div>

                        </Col>
                        <Col xl={2} lg={2} md={6}>
                            <div className={`${style.profile_other_box} border-0`}>
                                <div className={style.profile_other_box_cnt}>
                                    <Skeleton width={100} />
                                </div>
                                <div className={style.profile_other_box_cnt}>
                                    <Skeleton width={100} />
                                </div>
                            </div>
                        </Col>

                    </Row>
                }

                {props.profile &&


                    <Row>
                        <Col xl={4} lg={4} md={12}>
                            <div className={style.profile_main_sec}>
                                <div className={style.profile_main_img_bx}>

                                    <img src={props.profile.user_photo} alt="" /></div>
                                <div className={style.profile_main_txt_sc}>
                                    <strong>{props.profile.full_name} </strong>
                                    <span>{props.profile.user_name}</span>
                                    <Link to="/profile" className={style.view_prfl_btn}>{t('Dashboard.viewProfile')}</Link>
                                </div>
                            </div>

                        </Col>
                        {(props.profile.replica) &&
                            <Col xl={2} lg={2} md={12}>
                                <div className={style.profile_soc_share_sec}>
                                    {props.profile.replica &&
                                        <div className={style.profilesoc_share_bx}>
                                            <div className={style.profilesoc_share_bx_hd}>{t('Dashboard.replicaLinks')}</div>
                                            <div className={style.profilesoc_share_bx_row}>

                                                {props.profile.replica.map((link, index) => {


                                                    return <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />

                                                })}

                                            </div>
                                        </div>
                                    }
                                      
                                    {/* {props.profile.lead_capture &&
                                        <div className={style.profilesoc_share_bx}>


                                            <div className={style.profilesoc_share_bx_hd}>{t('Dashboard.leadCapture')}</div>
                                            <div className={style.profilesoc_share_bx_row}>

                                                {props.profile.lead_capture.map((link, index) => {

                                                    return <Links iscopy={link.icon === "fa fa-files-o" ? true : false} icon={link.icon} key={index} link={link.link} />

                                                })}

                                            </div>
                                        </div>
                                    } */}
                                </div>

                            </Col>
                        }
                        {(props.profile.membership_package || props.profile.rank) &&
                            <Col xl={2} lg={2} md={4}>
                                <div className={style.profile_other_box}>
                                    {props.profile.membership_package &&
                                        <div className={style.profile_other_box_cnt}>
                                            <span>
                                                {t('Dashboard.membershipPackage')}
                                                <strong>{props.profile.membership_package.name ?? 'NA'} </strong>
                                            </span>
                                        </div>
                                    }
                                    {props.profile.rank &&
                                        <div className={style.profile_other_box_cnt}>
                                            <span>
                                                {t('Common.rank').toUpperCase()}
                                                <strong>{props.profile.rank.curent_rank}</strong>
                                            </span>
                                        </div>
                                    }

                                </div>

                            </Col>
                        }
                        <Col xl={4} lg={4} md={6}>
                            <div className={`${style.profile_other_box} border-0`}>
                                <div className={style.profile_other_box_cnt}>
                                   
                                    <span>
                                 
                           
                                        {t('Common.placement')}:
                                        <strong>{props.profile.placement}</strong>
                                    </span>
                                </div>
                           
                         </div>

                        </Col>

                    
                    </Row>
                      
                }
            </div>
        </>

    )
}
export default Profiles;
