import React from 'react';
import {Row,Col,Card,Form} from 'react-bootstrap';
import style from './package.module.scss';
import {useTranslation} from 'react-i18next';
import AddonNotification from '../../components/common/AddonNotification';
import moment from 'moment';
import PaymentMethods from '../common/PaymentMethods';
import AlertBs from '../common/AlertBs';
import {useSelector} from 'react-redux';

function SubscriptionRenewal(props) {
    const {t} = useTranslation();
    const currency = useSelector(state=>state.curr);
    return (
        <div className={style.MainContainer}>
            <AddonNotification />
            <AlertBs 
                {...props.alert}
                close={props.closeAlert}
            />
        <Row>
            <Col className={style.ManiContent}>
                <Card >
                    <Card.Body className={style.ContentBody}>
                       <div className="upgrade-dec">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>{t('Dashboard.membershipExpiry')}</td>
                                        <td>:</td>
                                        <td>{moment(props.data.product.validity).format('MMMM Do YYYY, h:mm:ss a')}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('Dashboard.renewalCharge')}</td>
                                        <td>:</td>
                                        <td>{currency.currentCurr} {(parseInt(props.data.product.price)*currency.value).toFixed(currency.precision)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
                <div className={style.payments}>
                    <div className={style.paymentContainer}>
                        <legend>
                            <span>{t('Common.paymentOption')}</span>
                        </legend>
                        <Form onSubmit={props.onSubmit}>
                            {
                                props.paymentData.length>0&&
                                <PaymentMethods 
                                    methods={props.paymentData}
                                    selectedTab={props.data.selectedTab}
                                    product={props.data.product.id}
                                    payment_type="subscription_renewal"
                                    paymentMethodChange={props.tabChange}
                                    {...props}
                                />

                            }
                        </Form>
                    </div>
                </div>
            </Col>
        </Row>
    </div>
    )
}

export default SubscriptionRenewal
