import React, { useCallback, useEffect, useState } from 'react';
import style from './crm.module.scss';
import { Form } from 'react-bootstrap';
import { DatePickers, FormInputControl, DropDownBs, CustomButton, AlertBs } from '../common';
import moment from 'moment';
//service
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import CrmService from '../../../service/crm/Crm';
import AppinfoService from '../../../service/common/Appinfo';
const leadStatus = [
    {
        id: 'Ongoing'
    },
    {
        id: 'Rejected'
    },
    {
        id: 'Accepted'
    }

];
const interest = [
    {
        id: 'Very Interested'
    },
    {
        id: 'Interested'
    },
    {
        id: 'Not That Interested'
    }
];
const InitialForm = {
    first_name: '',
    last_name: '',
    email_id: '',
    skype_id: '',
    mobile_no: '',
    country: '',
    description: '',
    followup_date: '',
    lead_status: 'Ongoing',
    status_change_date: '',
    interest_status: 'Interested',

    formError: {
        first_name: '',
        last_name: '',
        email_id: '',
        skype_id: '',
        mobile_no: '',
        country: '',
        description: '',
        followup_date: '',
        lead_status: '',
        status_change_date: '',
        interest_status: '',
        id: ''
    }
}
function AddForm(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const service = new CrmService();
    const [status, setStatus] = useState(leadStatus);
    const [state, setState] = useState({
        ...InitialForm,
        alert: {
            show: false,
            message: '',
            varient: ''
        },
        editLoader: false,
    })
    //intial loading the country 
    //get the country in inital loading 
    const getCountry = useCallback(() => {
        const commonService = new AppinfoService();
        commonService.getCountry().then(res => {
            if (res.status) {
                setState(prev => ({
                    ...prev,
                    countryList: res.data.country
                }))
            } else {
                if (res.error.code === 1002) {
                    history.push('/logout')
                }
            }
        })
    }, [history])
    useEffect(() => {
        getCountry();
    }, [getCountry]);


    //get the edit data
    useEffect(() => {
        const { data, id } = props;

        setState(prev => ({
            ...prev,
            id: id,
            ...data
        }));
        // if(id){
        //     const addStatus = status;
        //     addStatus.push({
        //         id : 'Accepted'
        //     })
        //     setStatus(addStatus)
        // }
    }, [props, status])

    //changeHandler 
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setState(prev => ({
            ...prev,
            [name]: value,
            formError: {
                ...prev.formError,
                [name]: ''
            }
        }))
    }
    //date change function
    const datechange = (date, id) => {
        setState(prev => ({
            ...prev,
            [id]: date,
            formError: {
                ...prev.formError,
                [id]: ''
            }
        }))
    }

    //submit the 
    const onSubmitForm = (e) => {
        e.preventDefault();
        let isValid = true;
        let FormError = {}
        //validation in first nanme 
        if (!state.first_name) {
            isValid = false;
            FormError.first_name = {
                error: 'validation.required',
                field: {
                    field: 'firstName'
                }
            }
        }
        //validation in discription
        if (!state.description) {
            isValid = false;
            FormError.description = {
                error: 'validation.required',
                field: {
                    field: 'description'
                }
            }
        }
        //validatoion in follow up date
        if (!state.followup_date) {
            isValid = false;
            FormError.followup_date = {
                error: 'validation.required',
                field: {
                    field: 'nextFollowupDate'
                }
            }
        }
        if(state.email_id){
            var regexPattern=new RegExp(/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/);
            if(!regexPattern.test(state.email_id)){
                isValid = false;
                    FormError.email_id = {
                        error: 'validation.fieldAreNotCurrectFormat',
                        field: {
                            field: 'email_id'
                        }
                    }
            } 

        }
        if (isValid) {
            const today = moment();
            let FormData = JSON.parse(JSON.stringify(state));
            delete FormData.formError;
            delete FormData.alert;
            delete FormData.countryList;
            FormData.followup_date = moment(FormData.followup_date).format('yyyy-MM-DD');
            FormData.status_change_date = today.format('yyyy-MM-DD');
            if (props.isUpdate) {
                setState(prev => ({
                    ...prev,
                    editLoader: true,
                }))
                service.EditLead(FormData, props.id).then(res => {
                    if (res.status) {
                        setState(prev => ({
                            ...prev,
                            ...InitialForm,
                            editLoader: false,
                        }))
                        if (props.submitCallback) {
                            props.submitCallback();
                        } else {
                            setState(prev => ({
                                ...prev,
                                alert: {
                                    show: true,
                                    varient: 'success',
                                    message: 'validation.leadUpdatedSuccessfully'
                                }
                            }))
                        }
                        // props.LeadData();
                    } else {
                        if (res.error.code === 1002) {
                            history.push('/logout')
                        } else if (res.error.code === 1057) {
                            history.push({
                                pathname: '/dashboard',
                                state: {
                                    error: true,
                                    message: 'permissionDinied'
                                }
                            })
                        } else if (res.error.code === 1063 || res.error.code === 1004 ) {
                            setState(prev => ({
                                ...prev,
                                alert: {
                                    show: true,
                                    varient: 'danger',
                                    message: 'validation.checkValueYouHaveSubmitted'
                                },
                                editLoader: false,
                            }))
                        }
                    }
                })
            } else {
                setState(prev => ({
                    ...prev,
                    editLoader: true,
                }))
                service.AddLead(FormData).then((res) => {
                    if (res.status) {
                        setState(prev => ({
                            ...prev,
                            alert: {
                                show: true,
                                varient: 'success',
                                message: 'validation.leadAddedSuccessfully'
                            },
                            editLoader: false,
                            ...InitialForm
                        }))
                    } else {
                        if (res.error.code === 1002) {
                            history.push('/logout')
                        } else if (res.error.code === 1004) {
                            const { formError } = state;
                            let validationError = {};
                            for (const property in res.error.fields) {
                                if (formError.hasOwnProperty(property)) {
                                    validationError.followup_date = {
                                        error: `validation.${res.error.fields[`${property}_err`]}`,
                                        field: {
                                            field: property
                                        }
                                    }
                                }
                            }
                            setState(prev => ({
                                ...prev,
                                editLoader: false,
                                formError: {
                                    ...prev.formError,
                                    validationError
                                }
                            }))
                        }
                        else if(res.error.code ===1062){
                            setState(prev => ({
                                ...prev,
                                editLoader: false,
                                alert: {
                                    show: true,
                                    varient: 'danger',
                                    message: 'validation.emailAlreadyExists'
                                }
                            }))

                        }
                    }
                });
            }
        } else {
            setState(prev => ({
                ...prev,
                formError: {
                    ...prev.formError,
                    ...FormError
                },
                editLoader: false,
                alert: {
                    show: true,
                    varient: 'danger',
                    message: 'validation.checkValueYouHaveSubmitted'
                }
            }))
        }
    }
    //close alert
    const closeAlert = () => {
        setState(prev => ({
            ...prev,
            alert: {
                show: false,
                message: '',
                varient: ''
            }
        }))
    }
    return (
        <div className={`${style.panel} ${style.papanel_defaultnel}`}>
            <div className={`${style.panel_body}`}>
                <AlertBs
                    {...state.alert}
                    close={closeAlert}
                />
                <Form onSubmit={onSubmitForm} >
                    <FormInputControl
                        label={t('profile.firstName')}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'first_name',
                            placeholder: t('First Name'),
                            value: state.first_name,
                            onChange: changeHandler
                        }}
                        error={state.formError.first_name}
                    />
                    <FormInputControl
                        label={t("profile.lastName")}
                        inputProps={{
                            type: 'text',
                            name: 'last_name',
                            placeholder: t('Last Name'),
                            value: state.last_name,
                            onChange: changeHandler
                        }}
                    />
                    <FormInputControl
                        label={t('profile.email')}
                        inputProps={{
                            type: 'text',
                            name: 'email_id',
                            placeholder: t('Email ID'),
                            value: state.email_id,
                            onChange: changeHandler
                        }}
                        error={state.formError.email_id}
                    />

                    <FormInputControl
                        label={t('Common.skype')}
                        inputProps={{
                            type: 'text',
                            name: 'skype_id',
                            placeholder: t('Skype ID'),
                            value: state.skype_id,
                            onChange: changeHandler
                        }}
                    />
                    <FormInputControl
                        label={t('profile.mobile')}
                        inputProps={{
                            type: 'text',
                            name: 'mobile_no',
                            placeholder: t('Mobile No'),
                            value: state.mobile_no,
                            onChange: changeHandler
                        }}
                    />
                    <DropDownBs
                        label={t('profile.country')}
                        preOption="country"
                        options={{
                            data: state.countryList,
                            value: 'value',
                            label: 'title'
                        }}
                        DropdownProps={{
                            name: "country",
                            onChange: changeHandler,
                            value: state.country
                        }}
                    />

                    <FormInputControl
                        label={t('profile.description')}
                        required={true}
                        inputProps={{
                            type: 'text',
                            name: 'description',
                            placeholder: t('Description'),
                            value: state.description,
                            onChange: changeHandler
                        }}
                        error={state.formError.description}
                    />
                    <DropDownBs
                        label={t('Crm.levelOfInterest')}
                        options={{
                            data: interest,
                            value: 'id',
                            label: 'id'
                        }}
                        DropdownProps={{
                            name: "interest_status",
                            onChange: changeHandler,
                            value: state.interest_status,
                            
                        }}
                    />
                    {
                        !props.isUpdate &&
                        <DatePickers
                            label={t('Crm.nextFollowupDate')}
                            start={state.followup_date}
                            placeholderText={t('Common.fromDate')}
                            datechange={datechange}
                            id="followup_date"
                            error={state.formError.followup_date}
                            required={true}
                            minDate={moment().toDate()}
                        />
                    }
                    <DropDownBs
                        label={t('Crm.leadStatus')}
                        options={{
                            data: status,
                            value: 'id',
                            label: 'id'
                        }}
                        DropdownProps={{
                            name: "lead_status",
                            onChange: changeHandler,
                            value: state.lead_status
                        }}
                    />
                    <CustomButton
                        type="submit"
                        variant="primary"
                    >
                        {state.editLoader ?
                            <i className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                            /> : ''}

                        {t(`Button.${props.BtnName}`)}
                    </CustomButton>
                </Form>
            </div>
        </div>
    );
}
export default AddForm

