import {BASE_URL} from '../../configuration/apiconfig';
import UserStore from '../../store/UserStore';

export default class shoppingService {
    API_KEY=localStorage.getItem('apiKey')
    async getRepurchasePackageList() {
        const response = await fetch(BASE_URL+'package/repurchase_product', {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }

    async getRepurchasePackage(product_id) {
        const response = await fetch(`${BASE_URL}package/repurchase_product_details?product_id=${product_id}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }


    //update the cart items 
    async updateCart(rowId,quantity){
        const response = await fetch(`${BASE_URL}package/updateItem?row_id=${rowId}&quantity=${quantity}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            }
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }

    //addto cart
    async addToCart(data){
        const response = await fetch(`${BASE_URL}package/add_to_cart`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            },
            body : JSON.stringify(data)
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        } 
    }
    //get the cart items
    async getCart(){
        const response = await fetch(`${BASE_URL}package/getCartItems`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            }
        })
        const result = await response.json();
        return result;
    }
    //remove the cart items 
    async removeItems(id){
        const response = await fetch(`${BASE_URL}package/removeItems?row_id=${id}`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            }
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        } 
    }

    //get the user address 
    async getUserAddress(){
        const response = await fetch(`${BASE_URL}package/getUserAddress`, {     //url
            method: 'GET',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            }
        })
        const res = await response.json();
        return res; 
    }
    //remove the address 
    async removeAddress(id){
        const response = await fetch(`${BASE_URL}package/RemoveAddress`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            },
            body: JSON.stringify(id)
        })
        const res = await response.json();
        return res; 
    }

    //add the 
    async addAddress(data){
        const response = await fetch(`${BASE_URL}package/add_checkout_address`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            },
            body: JSON.stringify(data)
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }
    //set the default address
    async setDefaultAddress(data){
        const response = await fetch(`${BASE_URL}package/change_default_address`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            },
            body: JSON.stringify(data)
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }
    //submit the shopping cart 
    async submitShoppingCart(data){
        const response = await fetch(`${BASE_URL}cart/repurchase_submit`, {     //url
            method: 'POST',                 //method
            headers : {                     //passing header 
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json',
                'api-key'       : this.API_KEY,
                'access-token'  : UserStore.key
            },
            body: JSON.stringify(data)
        })
        if(response.status === 204){
            return { 
                status : true
            }
        }else{
            const res = await response.json();
            return res;
        }
    }
} 