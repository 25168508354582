import React from 'react';
import {Dropdown} from 'react-bootstrap';
import style from './dashboard.module.scss';
import {useTranslation} from 'react-i18next';
const FilterToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      className={style.TilesfilterIcon}
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  )); 
function TilesFilter(props) {
  const {t} = useTranslation();
    return (
        <>
          <Dropdown className={`${style.lst_box_quick_select}`}>
            <Dropdown.Toggle className={style.lst_box_quick_select_btn} >
            <i  style={{color:'black'}} className="fa fa-ellipsis-v"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight  className={style.dropdownmenu }>
            {
                props.items &&
                props.items.map((item,index)=>{
                  return <Dropdown.Item 
                  className={style.dropdownitem}
                    key={index} 
                    eventKey={item.code} 
                    onSelect={props.onChange}>
                      {t('Common.'+item.code)}
                    </Dropdown.Item>
                })
              }
          
          
            </Dropdown.Menu>
        </Dropdown>
        </>
    )
}

export default TilesFilter