import React, { useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import NewsService from '../../../service/tools/news';
import moment from 'moment';
import style from './tools.module.scss';
import {Row,Col, Card, Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
const ViewNews = () => {
    let { id } = useParams();
    const {t} = useTranslation();
    const [state,setState] =useState({
        newsData : '',
        recentNews : [],
        loader:false
    })
    useEffect(()=>{
        const service= new NewsService();
        setState(prev=>({
            ...prev,
            loader:true,
        }))
        service.viewNews(id).then((res)=>{
            setState({
                newsData : res.data.active_news[0],
                recentNews : res.data.recent_news,
                loader:false,
                
            });
        })
    },[id])



    return ( 
        state.loader?
        <Spinner/>:

        <div className={style.ViewNewsContainer}>
            {
                state.newsData&&
                <Row>
                    <Col lg={12}>
                        <Row>
                            <Col sm={9}>
                                <Card>
                                    <img src={state.newsData.news_image} alt="" className={style.newsImage}/>
                                    <div className={style.NewsDetails}>
                                        <h2 className={style.title}>{state.newsData.news_title}</h2>
                                        <p>{state.newsData.news_desc}</p>
                                        <div className={style.divider}></div>
                                        <div className={style.dateDetails}>
                                            <i className="fa fa-user"></i>
                                            <span className="mr-3"> &nbsp;by Admin</span>
                                            <i className="fa fa-clock-o"></i>
                                            <span>&nbsp; {moment(state.newsData.news_date).format('MM/DD/yyyy')}</span>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                            <Col sm={3}>
                                <h5>{t('News.recentNews')}</h5>
                                <div>
                                    {
                                        state.recentNews.map((items,index)=>(
                                            <Card className={style.recentNewsList} key={index}>
                                                <Card.Body>
                                                    <div className={style.linkContent}>
                                                        <Link to={"/news/"+items.news_id} className={style.NewsLinkButton}>
                                                            <img src={items.news_image} alt=""/>
                                                        </Link>
                                                        <div>
                                                            <div>
                                                                <Link to={"/news/"+items.news_id}>
                                                                    {items.news_title}
                                                                </Link>
                                                            </div>
                                                            <div>
                                                            {moment(items.news_date).format('MM/DD/yyyy')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        ))
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </div>
     );
}
 
export default ViewNews;