import React from 'react';
import {useHistory} from 'react-router-dom';
//css
import {Col} from 'react-bootstrap';
import style from './crm.module.scss';
function Tiles(props) {
    const history = useHistory();
    const ClickLink = () =>{
        history.push(`/viewLead/${props.filter}`)
    }
    return (
            <Col xs={12} lg={4} md={4}  xl={4} >
                <div className={`${style.card_1} text-center mrg_btm_tile_crm`}>
                    <div className={`${style.card_1} ${style[props.class_name]}`}>
                        <div className={`${style.card_body} ${style.stat_widget_seven}`}>
                            <div className={`${style.media} align-items-center ml-3`}>
                                <div className={`${style.media_body}`}>
                                <h3 className={`mt-0 mb-2 ${style.border_in}`}>
                                    {props.title}
                                    <span className="pull-right">{props.count}</span>
                                </h3>
                                <h5 className="text-uppercase">{props.today_title} {props.today}</h5>
                                <span className="pull-right h4"> 
                                    <span className="btn-link btn-icon text-white" onClick={ClickLink}>
                                        <i className="fa fa-arrow-circle-right"></i>
                                    </span> 
                                </span>
                                </div>
                            </div>
                        </div> 
                    </div>
                    
                </div>
            </Col>
    );
}
export default Tiles