import React, { useEffect } from 'react';
import style from './replica.module.scss';
import { Row, Col, Container, Navbar } from 'react-bootstrap';
import WOW from 'wowjs';



function Plans(props) {
    return (
        <section id="plan" className="plan_cnt_sec" >
             <div className="container">
            <div    dangerouslySetInnerHTML={{__html:props.data.policy_data.content?.plan&&props.data.policy_data.content?.plan}}></div>
         
            </div>
         
        </section>

    )
}

export default Plans