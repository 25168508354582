import React from 'react';
import {Line} from 'react-chartjs-2';
import {useTranslation} from 'react-i18next';


function CrmGraph(props){
  const {t} = useTranslation();
  const  monthData  = {
    labels: props.data.leads_day_ticks,
    datasets: [
      {
        label: t('Crm.accepted'),
        data:props.data.accepted_leads_day.map((obj,key)=>{ return obj.value}),
        fill: true,
        borderColor: "rgb(250,215,51)",
        borderWidth: 3,
      },
      {
        label: t('Crm.rejected'),
        data: props.data.rejected_leads_day.map((obj,key)=>{ return obj.value}),
        fill: true,
        borderColor: "rgb(114,102,186)",
        lineTension: 0.2,
        borderWidth: 3,
      },
      {
        label: t('Crm.ongoing'),
        data:props.data.ongoing_leads_day.map((obj,key)=>{ return obj.value}),
        fill: true,
        borderColor: "rgb(35,183,229)",
        lineTension: 0.2,
        borderWidth: 3,
      }
    ]
  };
const yearOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 5,
        fontSize: 12,
        fontColor: "gray"
      }
    },
    title: {
      display: true,
      text: t('Crm.Lty'),
      // padding: {
      //     top: 10,
      //     bottom: 30
      // }
  }
  };
  const MonthOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 5,
        fontSize: 12,
        fontColor: "gray"
      }
    },
    title: {
      display: true,
      text: t('Crm.Ltm'),
      // padding: {
      //     top: 10,
      //     bottom: 30
      // }
  }
  };


  const yearData  = {

    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
    datasets: [
      {
        label:  t('Crm.accepted'),
        data:props.data.accepted_leads_month.replace(/[\[\]']+/g,'').split(','),
        fill: true,
        borderColor: "rgb(250,215,51)",
        borderWidth: 3,
      },
      {
        label:t('Crm.rejected'),
        data: props.data.rejected_leads_month.replace(/[\[\]']+/g,'').split(','),
        fill: true,
        borderColor: "rgb(114,102,186)",
        lineTension: 0.2,
        borderWidth: 3,
      },
      {
        label:t('Crm.ongoing'),
        data:props.data.rejected_leads_month.replace(/[\[\]']+/g,'').split(','),
        fill: true,
        borderColor: "rgb(35,183,229)",
        lineTension: 0.2,
        borderWidth: 3,
      }
    ]
  };
return (

  <>
  <div className="graph_main_container">
  <div className='row'>
  <div className='col-md-6'>
      <div  className='graph_box_crm'>
              <Line
                data={yearData} 
                options={yearOptions}
      //           width={5}
      // height={3}
              >
              </Line>
              

        </div>
  </div>
  <div className='col-md-6'>
      <div  className='graph_box_crm'>
        <Line
          data={monthData} 
          options={MonthOptions}

        >
        </Line>
          
        </div>
</div>
</div>

</div>

</>
)


}
export default CrmGraph;



// export default class App extends React.Component {
//   render() {
//     return (
//       <div>
//         <Line
//           data={state}
//           options={{
//             title:{
//               display:false,
//               text:'Average Rainfall per month',
//               fontSize:20
//             },
//             legend:{
//               display:true,
//               position:'right'
//             },
//             tooltips:{

//             }
//           }}
//         >
//         </Line>
         
//       </div>
//     );
//   }
