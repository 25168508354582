import React from 'react';
import {Container} from 'react-bootstrap';
import style from '../style/App.module.scss';
import AppInfoservice from '../../service/common/Appinfo';
import { useState,useEffect } from 'react';
import {Language} from '../components/common/';
import Brand from '../components/auth/Brand';
function AuthLayout({children}) {
const service=new AppInfoservice();
const [cname, setcname] = useState('')
useEffect(()=>{
    service.Appinfo().then((res)=>{
        if(res.status){
           setcname(res.data.company_info.company_name) 
        }
    })
},[])
    return (
        <div className={`${style.app} pt-5`}>
            <Container className={style.loginContainer}>
                <Brand/>
                <Language classname="mb-4 w-100"/> 
                {children}
               </Container>
            <div className={style.lg_login_btn_btm_1}>
            2022 © {cname}
             </div> 
        </div>
    )
}

export default AuthLayout