import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';
import { routes, publicRoute ,replicaRoute} from "./routes";
import { MainLayout, AuthLayout ,} from '../layout';
import AppInfoservice from '../../service/common/Appinfo';
import UserStore from '../../store/UserStore';
import { useDispatch, useSelector } from 'react-redux';
import { islogged } from '../../store/action';
import Spinner from '../shared/Spinner';
import NotFount from '../components/404/NotFount';

import { useParams } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';
import ResetPassword from '../view/auth/login/ResetPassword';
import ResetTranPassword from '../view/auth/login/ResetTransactionPassword';
import Replica from "../view/Replica/Home";
import privacy from '../view/Replica/privacy';
import terms from '../view/Replica/terms';




const RouteMap = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const logged = useSelector(state => state.isLogged)


  const path = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get('token');
  let key = urlParams.get('key');

  let patharray = [
    '/reset_tran_password',
    '/reset_password',
    '/replica',
    '/privacy-policy',
    '/terms-conditions'
]






  useEffect(() => {
    if (code && key) {
      var decrypt = new JSEncrypt();
      console.log("code..",code,key)
      decrypt.setPrivateKey(process.env.REACT_APP_PRI);
      var uncrypted = decrypt.decrypt(decodeURI(code));
      console.log(uncrypted)
      localStorage.setItem('apiKey', key)
      const service = new AppInfoservice();
      let token = {
        token: uncrypted
      }
      service.check_token(token).then((res) => {
        setLoader(false)
        if (res.status) {
          localStorage.setItem('login', JSON.stringify({
            token: token.token,
          }));
          UserStore.key = token.token;
          UserStore.isLoggedIn = true;
          dispatch(islogged());
          history.push('/dashboard')
        }
        else {
          history.push('/login')

        }

      })

    }
  }, [])

  useEffect(() => {
    if (!code && !key) {
      const service = new AppInfoservice();
      let token = {
        token: UserStore.key
      }
      service.check_token(token).then((res) => {
        setLoader(false)
        if (res.status) {
          dispatch(islogged());
          if (window.location.pathname) {
            history.push(window.location.pathname === '/login' ? '/dashboard' : window.location.pathname)
          } else {
            history.push('/dashboard')
          }
        } else {
          console.log(window.location.pathname)

          if (window.location.pathname.includes('/reset_password') || window.location.pathname.includes('/reset_tran_password') || window.location.pathname.includes('/replica')|| window.location.pathname.includes('/privacy-policy')|| window.location.pathname.includes('/terms-conditions')|| window.location.pathname.includes('/replica_register')) {
            history.push(window.location.pathname)
           }
          else {
            history.push('/login')
          }

        }
      })
    }

  }, [history, dispatch])








  if (loader) {
    return <Spinner />
  }
  return (
    <Switch>
   <Route
        path="/reset_password/:resetkey"
        component={ResetPassword} />
         <Route
        path="/n_password/:resetkey"
        component={ResetTranPassword} /> 

        
          {replicaRoute.map((component,index)=>(
            <Route path={component.path} exact={component.exact} key={index}>
             
            <component.component />
          </Route>
          ))}
      {
        logged ?
          <MainLayout>
            <Switch>
              {
                routes.map((component, index) => (
                  <Route path={component.path} exact={component.exact} key={index}>
                    <component.component />
                  </Route>
                ))


              }
              <Route path="/404" component={NotFount} />
              <Redirect to="/404" />
            </Switch>
          </MainLayout> :
          <AuthLayout>
            {
              publicRoute.map((component, index) => (
                <Route path={component.path} exact={component.exact} key={index}>
                  <component.component />
                </Route>
              ))
            }
          </AuthLayout>
      }
    </Switch>
  );
}

export default RouteMap;