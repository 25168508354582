import React from 'react';
import style from './crm.module.scss';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import companyName from '../../../store/mobxStore/companyName';
// import AddFollowUp from './AddFollowUp';
function LeadHistory(props) {
    const {t} = useTranslation();
    return (
        <div className={`${style.leadHistoryContent}`}>
            <h4 className="text-center">{t('lead.leadHistory')}</h4>
            <p className="text-center">{props.lead_details.first_name} &nbsp;{props.lead_details.last_name?props.lead_details.last_name:''}</p>
            <div className="text-center">
                <span  onClick={()=>props.showModal(props.lead_details.id,'addFollowup')} className={`btn-link ${style.add_followup_btn}`}>
                    <i className="fa fa-plus">  </i>
                     {t('Crm.addFollowup')}
                </span>
            </div>            
            <div className={`${style.timeline}`}>
                <div className={`${style.demo_card_wrapper}`}>
                    <div className={`${style.demo_card} ${style.demo_card_step2}`}>
                        <div className={`${style.head}`}>
                            <div className={`${style.number_box}`}> <span>1</span> 
                            </div>
                                <h4 className={``}>
                                {t('Crm.introducedTo')} {companyName.companyName} by {props.lead_details.added_by_name}
                                </h4>
                        </div>
                            <div className={`${style.body}`}>
                                <p className="desc">{t('Crm.on')} :  <b>{props.lead_details.date}</b></p>
                                <p className="desc">{props.lead_details.description}</p>
                            </div>
                    </div>
                    { 
                        props.followup&&props.followup.map((item, key) => {
                            return (
                            <div className={`${style.demo_card} ${style.demo_card_step1}`} key={key}>
                                <div className={`${style.head}`}>
                                    <div className={`${style.number_box}`}> <span>{key+2}</span> 
                                    </div>
                                    <h4 className={``}>
                                       {t('Crm.followupOn')} {moment(item.date).format('yyyy-MMM-DD')} by {item.add_by}
                                    </h4>
                                </div>
                                <div className={`${style.body}`}>
                                    <p className="desc">{item.description}</p>
                                    <p className="desc">{t('Crm.nextFollowupDate')} : <b>{moment(item.followup_date).format('yyyy-MMM-DD')}</b></p>
                                    {item.file_name&&
                                        <div className="text-center">
                                        <a className={`${style.btn} ${style.attachment}`}  style={{textDecoration:'none'}} target="_blank" href={item.file_name} download >{t('Crm.downloadAttachment')} <i className="fa fa-download" aria-hidden="true"></i></a>
                                        </div>
                                    }
                                </div>
                            </div>
                            )
                        })
                    } 
                </div>
            </div>
        </div>
    )
}

export default LeadHistory