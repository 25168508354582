import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from "react-helmet";
import { useTranslation, withTranslation } from 'react-i18next'
// import {Redirect}           from 'react-router-dom';
import { Alert,Form } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { Link, withRouter, Redirect, useHistory } from 'react-router-dom';
//components
import Forms from '../../../components/auth/Forms';
import LoginButton from '../../../components/auth/LoginButton';

//service
import LoginService from '../../../../service/Auth/Login';
import { islogged } from '../../../../store/action';
import UserStore from '../../../../store/UserStore';
import style from '../../../components/auth/auth.module.scss';
import { COMPANY_NAME } from '../../../../configuration/apiconfig';

import { useParams } from 'react-router-dom';
import AppInfoservice from '../../../../service/common/Appinfo';
import { useDispatch } from 'react-redux';
import ForgotPasswordService from '../../../../service/Auth/ForgotPassowrd';



import JSEncrypt from 'jsencrypt';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';



const formValid = formError => {
    let valid = true;
    Object.values(formError).forEach(
        val => val.length > 0 && (valid = false)
    );
    return valid;
}
const mapStateToProps = (state) => {
    return { proPs: state }

};
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ islogged }, dispatch)
}

function Login(proPs) {
    const params = useParams()

    const { t } = useTranslation()


    const [state, setState] = useState({
        userName: params.username ? params.username : '',
        password: params.username && params.adminusername ? '123456' : '',
        adminuser: '',
        isLoggedError: false,
        isLogged: false,
        unapproved: false,
        captchaImage:'',
        captcha:'',
        FormError: {
            userName: "",
            password: "",
            adminuser: "",
            captcha:"",
        },
        btnDisabled: false,
        loader: false,
    })
   


    onchange = e => {
        e.preventDefault();
        const { name, value } = e.target;//get the field name
        setState(prevState => ({
            ...prevState,
            [name]: value,
            FormError: {
                [name]: ""
            }

        }));
    }
    const resetform = () => {
        document.getElementById('login-form').reset();//reset form
        setState(prevState => ({ //reset state
            ...prevState,
            userName: '',
            password: ''
        }));
    }

    const reloadCaptcha = (userName) => {
        const service = new ForgotPasswordService();
        service.getCaptcha(userName).then((res) => {
            console.log(res)
            let outside = URL.createObjectURL(res);
            setState(prev => ({
                ...prev,
                captchaImage: outside
            }))
        })
    }



    const handleAlertDismiss = () => {
        setState(prevState => ({
            ...prevState,
            isLoggedError: false
        }));
    }
    const blurhandler = (name) => {
        const service = new ForgotPasswordService();
        if (name === 'userName') {
            const formData = {
                user_name: state.userName
            }
            service.checkUsername(formData).then(res => {
                if (!res.status) {
                    if (res.error.code == '1043') {
                        setState(prevState => ({
                            ...prevState,
                            FormError: {
                                ...prevState.FormError,
                                userName: 'Username_not_exists'
                            }
                        }));
                    }
                }
                else {
                    reloadCaptcha(state.userName);
                    setState(prevState => ({
                        ...prevState,
                        FormError: {
                            ...prevState.FormError,
                            userName: ''
                        }
                    }));


                }
            })
        }

    }


    const requiredValid = (value, name) => {
        const FormError = state.FormError;
        switch (name) {
            case 'userName':
                FormError.userName = value.length === 0 ? "required" : "";
                break;
            case 'password':
                FormError.password = value.length === 0 ? "required" : "";
                break;
            case 'captcha':
                FormError.captcha = value.length === 0 ? "required" : "";
                break;
            default:
                break;
        }

        setState(prevState => ({
            ...prevState,
            FormError
        }));

    }
    const onSubmit = async e => {
        const FormError = state.FormError;
        e.preventDefault();//prevent default  
        requiredValid(state.userName, 'userName');
        requiredValid(state.password, 'password');
    //    requiredValid(state.captcha, 'captcha');
    //     if(state.captcha){
    //     if (validateCaptcha(state.captcha)==true) {
    //         FormError.captcha = ""
    //     }
    //     else{
    //         FormError.captcha = "invalidCaptcha"
    //     }
    // }
   
        const service = new LoginService();
        if (formValid(state.FormError)) {
            setState(prevState => ({
                ...prevState,
                loader: true
            }));
            service.getApi(state.adminuser).then(async res => {
                if (res.status) {
                    let cridential = {
                        username: state.userName,
                        password: state.password
                    };
                    setState(prevState => ({
                        ...prevState,
                        loader: false
                    }));
                    await localStorage.setItem('apiKey', res.data.key);
                    UserStore.apiKey = res.key;
                    await service.LoginUser(cridential).then((res) => {
                        if (res.status) {
                            resetform();
                            localStorage.setItem('login', JSON.stringify({
                                token: res.data.access_token,
                            }));
                            UserStore.key = res.data.access_token;
                            UserStore.isLoggedIn = true;
                            setState(prevState => ({
                                ...prevState,
                                isLogged: true
                            }));
                            proPs.islogged();

                        } else if (res.status.unapproved === true) {
                            setState(prevState => ({
                                ...prevState,
                                isLogged: false,
                                unapproved: true
                            }));

                        }

                        else {
                            setState(prevState => ({
                                ...prevState,
                                isLoggedError: true
                            }));
                        }

                    })
                }
                else {
                    setState(prevState => ({
                        ...prevState,
                        loader: false
                    }));
                    if (res.error.code === 1042) {
                        setState(prevState => ({
                            ...prevState,
                            isLoggedError: true
                        }));
                    } else if (res.error.code === 1001) {
                        setState(prevState => ({
                            ...prevState,
                            isLoggedError: true
                        }));
                    }

                }
            })

        }
        else {

            return false;
        }
    }
    return (
        <>
            <div className="mb-2">
                <Helmet>
                    <title>{COMPANY_NAME}</title>
                </Helmet>
                {
                    proPs.isLogged || state.isLogged ? <Redirect to="/dashboard" /> : ""
                }

                {
                    state.unapproved ? <Redirect to="/unapproved/dashboard" /> : ""
                }

                {
                    state.isLoggedError ?
                        <Alert dismissible variant="danger"
                            onClose={handleAlertDismiss}>
                            {t('Login.Error.LoginError')}
                        </Alert> : ""
                }


                <form id="login-form">

                    {process.env.REACT_APP_STATUS === "DEMO" &&
                        <Forms
                            type="text"
                            name="adminuser"
                            change={onchange}
                            error={state.FormError.adminuser}
                            value={state.adminuser}

                        />
                    }

                    <Forms
                        type="text"
                        name="userName"
                        change={onchange}
                        error={state.FormError.userName}
                        value={state.userName}
                        blurhandler={blurhandler}
                        from={'login'}
                    />
                    <Forms
                        type="password"
                        name="password"
                        change={onchange}
                        error={state.FormError.password}
                        value={state.password}
                    />
                  
                  
                        {/* <>
                            <React.Fragment >
                                <Form.Group style={{ textAlign: 'center' ,background:'aliceblue',padding:'10px'}}>
                                <LoadCanvasTemplate  />
                                </Form.Group>
                                <Forms
                                    type="text"
                                    name="captcha"
                                    change={onchange}
                                    error={state.FormError.captcha}
                                />


                            </React.Fragment>

                        </> */}
                        <div className={style.ForgotSection}>
                        <div>
                            <Link to='/forgotPassword'>
                                {t('Common.forgotPassword')}?
                            </Link>

                        </div>
                      
                    </div>

                    

                    <LoginButton
                        submit={onSubmit}
                        disabled={state.btnDisabled}
                        {...state}
                    />
                      
                      
                    <div className={style.lg_login_btn_btm}>
                        Don't have an account? <a href="http://mycoopmarket.com/store/index.php?route=register/mlm">Signup now</a>
                    </div>
                </form>





            </div>


        </>

    )

}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Login)));