// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.7.74/checkout/react/mlm/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://192.168.7.74/checkout/react/mlm/uploads/images/";
// const BASE = "http://192.168.7.74/checkout/react/mlm";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = 'a201cb4c-0fa2-e8bae44e-aee1-3bfceb9a5fc5'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
    
//     BASE,
//     DEFAULT_KEY
// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://demo3.infinitemlmdemo.com/widens/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "https://demo3.infinitemlmdemo.com/widens/uploads/images/";
// const BASE = "https://demo3.infinitemlmdemo.com/widens/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '2ffcf9da-b590-4472-ad7d-892c04c34c64'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,   
//     BASE,
//     DEFAULT_KEY
// }


import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "http://mycoopmarket.com/backoffice/api/";
// export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
const IMG_URL = "http://mycoopmarket.com/uploads/images/";
const BASE = "http://mycoopmarket.com";
// export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
const DEFAULT_KEY = 'fe1057eb-0c67-4dfe-9216-6d124161f50f'
console.log(localStorage);
export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    
    BASE,
    DEFAULT_KEY
}

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://mlmadmin.demo5.iossmlm.com/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://mlmadmin.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://mlmadmin.demo5.iossmlm.com/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = '14869ac2-d19b-497b-b0bb-4845393e11f9'
// //const DEFAULT_KEY = '14963'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// const IMG_URL = "https://backoffice.infinitemlmsoftware.com/uploads/images/";
// const BASE = "https://backoffice.infinitemlmsoftware.com";
// const API_KEY =localStorage.getItem('apiKey');
// const DEFAULT_KEY = '14963'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE, 
//    DEFAULT_KEY

// }